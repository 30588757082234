import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AuthenticationService } from '../_services/authentication.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { GlobalService } from '../_services/global.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  returnUrl: string;
  error: '';
  user: any;
  constructor (
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private globalService: GlobalService
  ) { }

  ngOnInit() {

    this.authenticationService.loggedInObserver.subscribe(
      loggedIn => {
        this.redirectToHome();
      }
    )

    this.loginForm = new FormGroup({
      username: new FormControl(''),
      password: new FormControl('')
    })
  }

  get f() {
    return this.loginForm.controls;
  }

  loginSubmit() {
    this.globalService.startLoader();
    this.authenticationService.login(this.f.username.value, this.f.password.value)
        .pipe(first())
        .subscribe(
          response => {                        
            this.user = response.user_details;
            this.globalService.stopLoader();
            this.globalService.showMessage("Successfully Logged in", "Login");
            this.redirectToHome();
          },
          error => {
            this.error = error;
            this.globalService.showError("Incorrect Username or Password", "Login");
            this.globalService.stopLoader();
          }
        )
  }

  redirectToHome() {
    if (this.globalService.isUserAdmin()) {
      return this.router.navigate(['/dashboard']);
    } else {
      return this.router.navigate(['/students/menu'])
    }
    
    if(this.user.roles.indexOf('student') !== -1) {
      return this.router.navigate(['/student-home']);
    } else if(this.user.roles.indexOf('staff') !== -1) {
      return this.router.navigate(['/staff-home']);
    } else if(this.user.roles.indexOf('parent') !== -1) {
      return this.router.navigate(['/parent-home']);
    } else {
      return this.router.navigate(['/dashboard']);
    }          
  }
}
