import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private getPrintUrl = environment.API_URL + '/print';

  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
  ) { }

  sectionWiseFeePrint(section_id: number, fee_type_id: any) {
    return this.httpClient.get(this.getPrintUrl + '/section_wise_fee/' + section_id + '/' + fee_type_id);
  }

  classWiseFeePrint(class_id: number, fee_type_id: any) {
    return this.httpClient.get(this.getPrintUrl + '/class_wise_fee/' + class_id + '/' + fee_type_id);
  }

  courseWiseFeePrint(course_id: number, fee_type_id: any) {
    return this.httpClient.get(this.getPrintUrl + '/course_wise_fee/' + course_id + '/' + fee_type_id);
  }

  paymentsPrint(student_id: number, fee_type_id: any) {
    return this.httpClient.get(this.getPrintUrl + '/payments/' + student_id + '/' + fee_type_id);
  }

  transactionsPrint(data: any) {
    return this.httpClient.post(this.getPrintUrl + '/transactions', data);
  }

  sectionWiseStudentsList(data: any) {
    return this.httpClient.post(this.getPrintUrl + '/section_wise_students_list', data);
  }

  getBonafide(student_id: number) {
    return this.httpClient.get(this.getPrintUrl + '/bonafide/' + student_id);
  }

  open(url: string) {
    const link = this.document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.click();
    link.remove();
  }
}
