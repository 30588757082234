import { Component, EventEmitter, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AttendanceService } from 'src/app/_services/operator/attendance.service';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { GlobalService } from 'src/app/_services/global.service';
@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.scss']
})
export class AddAttendanceComponent implements OnInit, OnChanges {

  attendanceMenuItem: string;
  attendanceForm: FormGroup;
  sessions: any;
  section_id: number;
  students: any;
  absent_ids: number[] = [];
  displayedColumns = ['s_no', 'student_name', 'father_name', 'status_btn'];
  showDeleteAttendance: boolean = false;
  attendance_id: number;

  constructor(
    private fb: FormBuilder,
    private attendanceService: AttendanceService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.attendanceMenuItem = 'add-attendance';
    this.attendanceForm = this.fb.group({
      select_session: ['', Validators.required],
      attendance_date: [new Date(), Validators.required]
    });

    this.attendanceService.getSessions().subscribe(
      sessions => {
        this.sessions = sessions;
        if (sessions[0]) {
          this.attendanceForm.get('select_session').setValue(sessions[0]['id']);
          this.detectFormChange();
        }
      }
    );
  }

  ngOnChanges() {
  }

  selectedSection(section_id: number) {
    this.section_id = section_id;
    if (section_id) {
      this.attendanceService.getStudents(section_id).subscribe(
        data => {
          this.students = data;
        }
      )

      if (this.sessions && this.sessions.length > 0) {
        this.attendanceForm.get('select_session').setValue(this.sessions[0]['id']);
        this.detectFormChange();
      }
    }
  }

  absentClick(student_id) {
    if (this.absent_ids.indexOf(student_id) === -1) {
      this.absent_ids.push(student_id);
    }
  }

  presentClick(student_id) {
    this.absent_ids = this.absent_ids.filter(id => id !== student_id);
  }

  markAttendanceClick() {
    let attendance_request = {
      section_id: this.section_id,
      session_id: this.attendanceForm.get('select_session').value,
      date: this.attendanceForm.get('attendance_date').value,
      absent_ids: this.absent_ids
    };
    this.globalService.startLoader();
    this.attendanceService.saveAttendance(attendance_request).subscribe(
      response => {
        this.attendance_id = response['id'];
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Save');
        this.showDeleteAttendance = true;
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Save');
      }
    );
  }

  onDateChange(event): void {
    this.detectFormChange();

  }

  getSession(event) {
    this.detectFormChange();
  }

  detectFormChange() {
    let date = this.attendanceForm.get('attendance_date').value;
    date = moment.utc(date).startOf('day').toISOString();
    let session = this.attendanceForm.get('select_session').value;
    this.globalService.startLoader();
    if(this.attendanceForm.valid) {
      this.attendanceService
          .getAttendance(this.section_id, session, date)
          .subscribe(
            attendance_data => {
              if (attendance_data['section_id']) {
                this.attendance_id = attendance_data['id'];
                this.absent_ids = attendance_data['absent_ids'];
                this.showDeleteAttendance = true;
              } else {
                this.absent_ids = [];
                this.showDeleteAttendance = false;
              }
              this.globalService.stopLoader();
            },
            error => {
              this.globalService.showError('Something went wrong', 'Attendance');
              this.globalService.stopLoader();
            }
          )
    }
  }

  deleteAttendanceClick() {
    if (this.attendance_id) {
      this.attendanceService
          .deleteAttendance(this.attendance_id, this.section_id)
          .subscribe(
            response => {
              this.detectFormChange()
              this.globalService.stopLoader();
              this.globalService.showMessage(response['message'], 'Delete');
              this.showDeleteAttendance = true;
            },
            error => {
              this.globalService.stopLoader();
              this.globalService.showError(error['error'], 'Delete');
            }
          )

    }
  }

}
