<app-transaction-menu [transactionMenuItem]="transactionMenuItem"></app-transaction-menu>

<mat-card class="transactions-filter-card">
    <mat-card-header *ngIf="isAdmin">
        <form [formGroup]="transactionsFilterForm" class="transactions-filter-form">
            <mat-form-field floatLabel="never">
                <input matInput formControlName="from_date" [matDatepicker]="from_picker" placeholder="From date" required>
                <mat-datepicker-toggle matSuffix [for]="from_picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #from_picker></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <mat-form-field floatLabel="never">
                <input matInput formControlName="to_date" [matDatepicker]="to_picker" placeholder="To date" required>
                <mat-datepicker-toggle matSuffix [for]="to_picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #to_picker></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Transaction Type" formControlName="type">                    
                    <mat-option value="income">Income</mat-option>
                    <mat-option value="expense">Expense</mat-option>
                    <mat-option value="both">Both</mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <mat-form-field floatLabel="never">
                <mat-select placeholder="Transaction Tag" formControlName="tag">                    
                    <mat-option value="all">All</mat-option>
                    <mat-option *ngFor="let tag of all_tags" value="{{ tag }}">{{ tag }}</mat-option>
                </mat-select>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <button mat-button color="custom-color" (click)="filterTransactions()">Filter</button>
        </form>
    </mat-card-header>
    <mat-card-content *ngIf="transactions && transactions.length > 0">
        <div style="height: auto;overflow: auto;">
            <table mat-table [dataSource]="transactions">
                <!-- S.No. Column -->
                <ng-container matColumnDef="s_no">
                    <th mat-header-cell *matHeaderCellDef> S.No. </th>
                    <td mat-cell *matCellDef="let transactions; let i = index;"> {{i+1}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
            
                <!-- Date Column -->
                <ng-container matColumnDef="transaction_date">
                    <th mat-header-cell *matHeaderCellDef> Date </th>
                    <td mat-cell *matCellDef="let transactions;"> {{transactions.transaction_date | date: 'dd-MM-yyyy'}} </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                
                <!-- Purpose Column -->
                <ng-container matColumnDef="purpose">
                    <th mat-header-cell *matHeaderCellDef> Purpose </th>
                    <td mat-cell *matCellDef="let transactions;"> {{transactions.purpose}} </td>
                    <td mat-footer-cell *matFooterCellDef style="text-align:left"><span>Total</span></td>
                </ng-container>

                <!-- Income Column -->
                <ng-container matColumnDef="income">
                    <th mat-header-cell *matHeaderCellDef> Income </th>
                    <td mat-cell *matCellDef="let transactions;"> {{transactions.transaction_type == 'income' ? transactions.amount : 0}} </td>
                    <td mat-footer-cell *matFooterCellDef><span>{{ total_income }}</span></td>
                </ng-container>

                <!-- Expense Column -->
                <ng-container matColumnDef="expense">
                    <th mat-header-cell *matHeaderCellDef> Expense </th>
                    <td mat-cell *matCellDef="let transactions;"> {{transactions.transaction_type == 'expense' ? transactions.amount : 0}} </td>
                    <td mat-footer-cell *matFooterCellDef><span>{{ total_expense }}</span></td>
                </ng-container>

                <!-- Proof Column -->
                <ng-container matColumnDef="proof">
                    <th mat-header-cell *matHeaderCellDef> Supporting docs </th>
                    <td mat-cell *matCellDef="let transactions;"> 
                        <a *ngIf="transactions.file_url" href="{{transactions.file_url}}" target="_blank">
                            <button mat-icon-button>
                                <mat-icon aria-label="description">description</mat-icon>
                            </button>
                        </a>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>

                <!-- Action Column -->
                <ng-container matColumnDef="action">
                    <th mat-header-cell *matHeaderCellDef> Action </th>
                    <td mat-cell *matCellDef="let transactions;"> 
                        <button *ngIf="transactions.id !== null" style="color:darkcyan" mat-icon-button color="green-color" (click)="openTransactionFormDialog(transactions.id)">
                            <mat-icon aria-label="edit">edit</mat-icon>
                        </button>
                        <button *ngIf="transactions.id !== null && isAdmin" style="color:firebrick" mat-icon-button color="red-color" (click)="deleteTransaction(transactions.id)">
                            <mat-icon aria-label="delete">delete</mat-icon>
                        </button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
            <div class="total_balance">Remaining Balance: Rs. {{ total_income - total_expense }}/-</div>
        </div>
    </mat-card-content>
    <mat-card-actions *ngIf="isAdmin && transactions && transactions.length > 0" class="text-center pt-2">
        <br/>
        <button mat-button color="custom-color" (click)="print()">Print</button>
    </mat-card-actions>
</mat-card>