import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  public page_title: BehaviorSubject<string> = new BehaviorSubject<string>('Dashboard');

  page_header = this.page_title.asObservable();

  constructor(
    private snackbar: MatSnackBar,
    private loaderService: NgxUiLoaderService,
    private authService: AuthenticationService,
    private device: DeviceDetectorService
  ) { }
  
  showError(error: string, action: string) {
    this.snackbar.open(error, action, {
      duration: 3000
    });
  }

  showMessage(message: string, action: string) {
    this.snackbar.open(message, action, {
      duration: 3000
    });
  }

  startLoader() {
    this.loaderService.start();
  }

  stopLoader() {
    this.loaderService.stop();
  }

  isUserAdmin() {
    return this.authService.getUserRoles().indexOf('admin') > -1;
  }

  isUserIncharge() {
    return this.authService.getUserRoles().indexOf('incharge') > -1;
  }

  isUserOperator() {
    return this.authService.getUserRoles().indexOf('operator') > -1;
  }

  isMobile() {
    return this.device.isMobile();
  }

  isDesktop() {
    return this.device.isDesktop();
  }

  isTablet() {
    return this.device.isTablet();
  }
}
