import { Component, ElementRef, Inject, Input, OnInit, Optional, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/_services/global.service';
import { TransactionService } from 'src/app/_services/transaction.service';
import { TransactionFormDialogData } from '../transactions-list/transactions-list.component';
import { COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-transaction-card',
  templateUrl: './transaction-card.component.html',
  styleUrls: ['./transaction-card.component.scss']
})
export class TransactionCardComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  transactionForm: FormGroup;
  transaction_type: string;
  file_data: File = null;
  preview_url: any = null;
  transaction_id: number;
  tags: string[] = [];
  allTags: string[];
  filteredTags: Observable<string[]>;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutoComplete: MatAutocomplete;

  constructor(private fb: FormBuilder,
    private transactionService: TransactionService,
    private globalService: GlobalService,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialog_data: TransactionFormDialogData ) {
      this.transactionForm = this.fb.group({
        transaction_date: [new Date()],
        amount: [],
        purpose: [],
        receipt_file: [],
        tagCtrl: []
      });

      this.getAllTransactionTags();
    }

  ngOnInit() {

    if (this.dialog_data && this.dialog_data.transaction_id !== undefined) {
      this.transaction_id = this.dialog_data.transaction_id;
      this.globalService.startLoader();
      this.transactionService
          .getTransaction(this.transaction_id)
          .subscribe(
            transaction => {
              this.transactionForm.patchValue({
                transaction_date: new Date(transaction.transaction_date),
                amount: transaction.amount,
                purpose: transaction.purpose,
                receipt_file: transaction.file,
                tag: transaction.tag
              });
              this.tags.push(transaction.tag);
              this.globalService.stopLoader();
            },
            error => {
              this.globalService.stopLoader();
            }
          )
    }
    this.globalService.stopLoader();
  }

  getAllTransactionTags() {
    this.transactionService.getTags().subscribe(
      tags => {
        this.allTags = tags;
        this.filteredTags = this.transactionForm.get('tagCtrl').valueChanges.pipe(
          startWith(null),
          map((tag: string | null) => {
            if (this.tags.length == 1) {
              return [];
            }
            return tag ? this._filter(tag) : this.allTags.slice()
          })
        );
      },
      error => {
        this.globalService.showError(error.error, 'Tags');
      }
    );
  }

  addTag(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;

    // Add our tag
    if ((value || '').trim() && this.tags.length == 0) {
      this.tags.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.transactionForm.get('tagCtrl').setValue(null);
    this.transactionForm.get('tagCtrl').disable();
  }

  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    this.transactionForm.get('tagCtrl').enable();
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  cashInClicked()
  {
    this.pushTagsWithoutTabEnter();
    this.transaction_type = 'income';
    this.saveTransaction();
    this.transactionForm.get('amount').reset();
    this.transactionForm.get('purpose').reset();
    this.transactionForm.get('receipt_file').reset();
    this.transactionForm.get('tagCtrl').reset();
    this.tags = [];
  }

  cashOutClicked()
  {
    this.pushTagsWithoutTabEnter();
    this.transaction_type = 'expense';
    this.saveTransaction();
    this.transactionForm.get('amount').reset();
    this.transactionForm.get('purpose').reset();
    this.transactionForm.get('receipt_file').reset();
    this.transactionForm.get('tagCtrl').reset();
    this.tags = [];
  }

  pushTagsWithoutTabEnter() {
    if (this.tags.length == 0) {
      this.tags.push(this.tagInput.nativeElement.value);
      this.tagInput.nativeElement.value = '';
      this.transactionForm.get('tagCtrl').setValue(null);
    }
  }

  transactionCreateForm(form_field) {
    return this.transactionForm.get(form_field).value;
  }

  onFileInput(e: any) {
    this.file_data =  <File>e.target.files[0];
  }

  saveTransaction()
  {
    const transaction_data = new FormData();
    transaction_data.append('transaction_date', new Date(this.transactionCreateForm('transaction_date')).toISOString());
    transaction_data.append('amount', this.transactionCreateForm('amount').toString());
    transaction_data.append('purpose', this.transactionCreateForm('purpose'));
    transaction_data.append('receipt_file', this.transactionCreateForm('receipt_file'));
    transaction_data.append('transaction_type', this.transaction_type);
    transaction_data.append('tag', this.tags.toString());
    this.globalService.startLoader();
    if (this.transaction_id) {
      this.updateTransaction(transaction_data);
    } else {
      this.createTransaction(transaction_data);
    }
  }

  createTransaction(transaction_data: FormData) {
    this.transactionService
        .createTransaction(transaction_data)
        .subscribe(
          response => {
            this.globalService.stopLoader();
            this.globalService.showMessage(response['message'], 'Create');
          },
          error => {
            this.globalService.stopLoader();
            this.globalService.showError(error['error'], 'Create');
          }
        );
  }

  updateTransaction(transaction_data: FormData) {
    this.globalService.startLoader();
    this.transactionService
        .updateTransaction(this.transaction_id, transaction_data)
        .subscribe(
          response => {
            this.globalService.stopLoader();
            this.globalService.showMessage(response['message'], 'Update');
          },
          error => {
            this.globalService.stopLoader();
            this.globalService.showError(error['error'], 'Update');
          }
        )
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.transactionForm.get('tagCtrl').disable();
    if (this.tags.length == 0) {
      this.tags.push(event.option.viewValue);
    }
    this.tagInput.nativeElement.value = '';
    this.transactionForm.get('tagCtrl').setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.tags.length == 1) {
      return [];
    }
    return this.allTags.filter(tag => tag.toLowerCase().indexOf(filterValue) === 0);
  }

}
