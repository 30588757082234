<mat-sidenav-container fullscreen>
    <mat-sidenav #sidenav mode="over">
        <mat-toolbar class="sidenav-toolbar-left">
            <div class="toolbar-filler">
<!--                <div>-->
<!--                    <img src="../../../assets/images/site_background.jpeg" class="user-profile-image" />-->
<!--                </div>-->
                <div class="mt-3" style="color: white;">
                    <span>{{username}}</span>
                </div>
            </div>
            <!-- <button mat-icon-button (click)="sidenav.toggle()">
                <mat-icon aria-label="Menu">close</mat-icon>
            </button> -->
        </mat-toolbar>
        <mat-nav-list class="ml-0 p-0">
            <mat-list-item *ngIf="isAdmin" class="mt-2 sidenav-text-color" (click)="sidenav.toggle()" [routerLink]="['/dashboard']">
                Dashboard
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle()" [routerLink]="['/students/view']">
                Students
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle()" [routerLink]="['/payments/track']">
                Payments
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('Income & Expense');" [routerLink]="['/transactions/create']">
                Income & Expenditure
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('Attendance');" [routerLink]="['/attendance/add']">
                Attendance
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('Reports');" [routerLink]="['/reports/categories']">
                Reports
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('SMS');" [routerLink]="['/sms/categories']">
              SMS
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('Alumni');" [routerLink]="['/alumni/view']">
                Alumni
            </mat-list-item>
            <mat-list-item  *ngIf="isAdmin" class="sidenav-text-color" (click)="sidenav.toggle(); setTitle('Class Transfer');" [routerLink]="['/class-transfer/promote']">
                Class Transfer
            </mat-list-item>
            <mat-list-item class="sidenav-text-color" (click)="sidenav.toggle()" [routerLink]="['/logout']">
                Logout
            </mat-list-item>
        </mat-nav-list>
        <!--  <mat-expansion-panel class="no-shadow my-0">
            <mat-expansion-panel-header fxLayout="row" [expandedHeight]="expandHeight"
                [collapsedHeight]="collapseHeight">
                <mat-panel-title>
                    Buy Airtime
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-nav-list class="ml-0" dense>
                <mat-list-item>
                    <a href="#">AirtelTigo Airtime</a>
                </mat-list-item>
                <mat-list-item>
                    <a href="#">Vodafone Airtime</a>
                </mat-list-item>
                <mat-list-item>
                    <a href="#">MTN Airtime</a>
                </mat-list-item>
                <mat-list-item>
                    <a href="#">GLO Airtime</a>
                </mat-list-item>
            </mat-nav-list>
        </mat-expansion-panel> -->
    </mat-sidenav>
    <mat-toolbar class="sidenav-toolbar">
        <button mat-icon-button (click)="sidenav.toggle()">
            <mat-icon aria-label="Menu">menu</mat-icon>
        </button>
        <span>{{page_header}}</span>
        <span class="toolbar-filler"></span>
         <span><i>{{ branch_header_text }}</i></span>
    </mat-toolbar>
    <mat-divider style="border: 1px solid white;"></mat-divider>
    <router-outlet></router-outlet>
</mat-sidenav-container>
