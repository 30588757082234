<mat-toolbar class="menu-horizontal">
    <div class="d-flex align-center" style="display: inline-block;">
        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': viewAlumni}" (click)="viewAlumniClick()">            
            <span>ALUMNI</span><br />
        </div>

        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': addAlumni}" (click)="addAlumniClick()">           
            <span>ADD ALUMNI</span><br />
        </div>

        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': searchAlumni}" (click)="searchAlumniClick()">           
            <span>SEARCH ALUMNI</span><br />
        </div>
    </div>
</mat-toolbar>
