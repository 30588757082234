import { Component, OnInit, Input, OnChanges, Output, EventEmitter, Inject, SimpleChange } from '@angular/core';
import { PaymentService } from 'src/app/_services/operator/payment.service';
import { PreviousPayments } from 'src/app/_models/previous_payments';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/_services/global.service';
import { EditStudentFeeDialogComponent } from '../edit-student-fee-dialog/edit-student-fee-dialog.component';
import { PrintService } from 'src/app/_services/operator/print.service';
export interface EditStudentFeeDialogData {
  student_id: number;
}
@Component({
  selector: 'app-previous-payments',
  templateUrl: './previous-payments.component.html',
  styleUrls: ['./previous-payments.component.scss']
})
export class PreviousPaymentsComponent implements OnInit, OnChanges {

  @Input() student_id: number;
  @Input() fee_type_id: number;
  @Input() payment_saved: boolean;
  @Output() editPaymentInfo = new EventEmitter<any>();  
  
  studentPreviousPayments: PreviousPayments[];
  fee_type: string;
  committed_fee: number;
  paid_total: number;
  pending_display: boolean = false;
  pending_fee: number;
  isAdmin: boolean;
  displayedColumns: string[] = ['s_no', 'receipt_date', 'receipt_number', 'amount', 'paid_by', 'collected_by','fee_type','action'];

  constructor(private printService: PrintService,
              private paymentService: PaymentService,
              public studentFeeDialog: MatDialog,
              private globalService: GlobalService) { }

  ngOnInit() {
    this.isAdmin = this.globalService.isUserAdmin();
    this.previousPayments();
    this.paymentService.payment_result.subscribe(
      response => {
        if (response) {
          this.previousPayments();
        } 
      }
    )
  }

  ngOnChanges(changes: { [propName: string] : SimpleChange }) {
    if( (changes['student_id'] && changes['student_id'].previousValue != changes['student_id'].currentValue) ||
        (changes['fee_type_id'] && changes['fee_type_id'].previousValue != changes['fee_type_id'].currentValue)
      ) {
      this.previousPayments();
    }
  }

  previousPayments() {
    this.globalService.startLoader();
    this.paymentService.previousPayments(this.student_id,this.fee_type_id).subscribe(
      response => {
        this.fee_type = response['fee_name'];
        this.committed_fee = response['committed_fee'];
        if(this.fee_type === 'Total Committed Fee') {
          this.pending_display = true;          
        }
        this.paid_total = response['paid_total'];
        this.pending_fee = response['pending_fee'];
        this.paymentService.fee_type_pending_balance.next(response['pending_fee']);
        this.studentPreviousPayments = response['payments'];
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.showError(error['error'], 'Payments');
        this.globalService.stopLoader();
      }
    )
  }

  editPayment(payment_id: string) {
    for(var i=0;i<this.studentPreviousPayments.length;i++) {
      if(this.studentPreviousPayments[i]['id'] == payment_id) {        
        this.editPaymentInfo.emit(this.studentPreviousPayments[i]);
      }
    }    
  }

  removePayment(payment_id: number) {
    if (confirm("Are you sure to delete this payment?")) {
      this.globalService.startLoader();
      this.paymentService.deletePayment(payment_id).subscribe(
        response => {
          this.globalService.showMessage(response['message'], 'Delete');
          this.globalService.stopLoader();
          this.previousPayments();        
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], 'Delete');
        }
      )
    }
  }

  openStudentFeeDialog() {
    this.globalService.startLoader();
    const studentFeeDialogRef = this.studentFeeDialog.open(EditStudentFeeDialogComponent, {
      data: {
        student_id: this.student_id
      } 
    });

    studentFeeDialogRef.afterClosed().subscribe(
      result => {
        this.previousPayments();
      }
    )
  }

  print() {
    this.printService
        .paymentsPrint(this.student_id, this.fee_type_id)
        .subscribe(
          response => {
            this.printService.open(response['url']);
          },
          error => console.log(error)
        );
  }

}
