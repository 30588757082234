import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClassService {

  private getClassesURL = environment.API_URL + '/classes';

  constructor(private httpClient: HttpClient) { }

  getClasses(courseId) {
    return this.httpClient.get(this.getClassesURL + '/' + courseId);
  }

  promoteClass(data) {
    return this.httpClient.post(this.getClassesURL + '/promote', data);
  }

}
