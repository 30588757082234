<app-student-menu [studentMenuItem]="studentMenuItem"></app-student-menu>

<app-select-section (section_id_emit)="selectedSection($event)"></app-select-section>

<div class="students-container page-content-view-port" *ngIf="section_id">
    <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="studentInfoFormGroup">
            <form [formGroup]="studentInfoFormGroup" class="add-student-form">
                <ng-template matStepLabel>Fill out the student details</ng-template>
                <mat-card class="add-student-card">
                    <mat-card-header>
                        <div class="example-header-image">
                            <img class="student-image" src="{{previewUrl}}" alt="Dummy student image"
                                (click)="studentImageInput.click()">
                            <input #studentImageInput type="file" (change)="onStudentImageInput($event)"
                                style="display:none;" />
                        </div>
                        <mat-card-title>
                            <mat-form-field class="add-student-form-fields" appearance="none">
                                <input matInput placeholder="Student Name" formControlName="student_name" required>
                            </mat-form-field>
                        </mat-card-title>

                        <mat-card-subtitle>
                            <mat-form-field class="add-student-form-fields" appearance="none">
                                <span>s/o</span><input matInput placeholder="Father Name" formControlName="father_name"
                                    required>
                            </mat-form-field>
                            <mat-form-field class="add-student-form-fields" appearance="none">
                                <input class="mt-0" matInput placeholder="Mobile Number" formControlName="mobile_number">
                            </mat-form-field>
                        </mat-card-subtitle>


                    </mat-card-header>
                    <mat-card-actions>
                        <div class="student-action-menu">
                            <button color="custom-color" mat-button (click)="saveStudent(stepper)">Save and
                                Continue</button>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </form>
        </mat-step>

        <mat-step [optional]="isOptional">
            <ng-template matStepLabel>Student Details</ng-template>
            <app-student-details [student_id]="student_id" (completed)="isStudentDetailsCompleted($event, stepper)"></app-student-details>    
        </mat-step>


        <mat-step>
        <ng-template matStepLabel>Done</ng-template>
        <div class="text-center mt-5">
                <h2>Student enrolled successfully</h2>
        </div>
        </mat-step>
    </mat-horizontal-stepper>
</div>