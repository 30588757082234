import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SmsService {

  private smsURL = environment.API_URL + '/sms';

  constructor(private httpClient: HttpClient) { }

  sendSMSFeeReminder(requestObj) {
    return this.httpClient.post(this.smsURL + '/section_wise', requestObj);
  }
}
