import { HttpClient } from '@angular/common/http';
import { User } from '../_models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {

    private loggedIn = new BehaviorSubject<boolean>(false);
    public branch_id: BehaviorSubject<Number> = new BehaviorSubject<Number>(null);
    public loggedInObserver = this.loggedIn.asObservable();

    constructor(
        private http: HttpClient,
        private router:Router
    ) {} //DevSkim: ignore DS137138 

    login(username: string, password: string) {        
        return this.http.post<any>(`${environment.API_URL}/login`, {username, password})
                    .pipe(map(response => {
                        //if successful login
                        if(response && response.token) {
                            const api = 'response';
                            const res = CryptoJS.AES.encrypt(JSON.stringify(response), api);
                            const myKey = CryptoJS.SHA256('currentuser', api);
                            localStorage.setItem(myKey.toString(), res.toString());
                            this.branch_id.next(response.user_details.branch_id);
                            this.loggedIn.next(true);
                        }                        
                        return response;
                    }))
    }

    logout() {
        // remove user from local storage to log user out
        const api = 'response';
        const key_index = this.getIndexByKey('currentuser');
        const local_storage_key = localStorage.key(key_index);
        const myKey = CryptoJS.SHA256('currentuser', api);
        if(myKey.toString() == local_storage_key) {            
            this.http.post<any>(`${environment.API_URL}/logout`, '')
                    .subscribe(
                        data => '',//console.log(data),
                        error => ''//console.log(error)
                    );
            localStorage.removeItem("courses");
            localStorage.removeItem(local_storage_key);
        }
        this.loggedIn.next(false);
        this.router.navigate(['/login']);
    }

    getIndexByKey(key): any {
        const api = 'response';
        const myKey = CryptoJS.SHA256(key, api);
        for(let index=0;index<localStorage.length;index++) {
            if(localStorage.key(index) == myKey.toString()) {
                return index;
            }
        }
    }

    getToken(): string {
        const data = this.decryptData('currentuser');
        let token = null;
        if(data) {
            token = data.token;
        }
        return token;
    }

    decryptData(key) {
        let result;
        const api = 'response';
        const key_index = this.getIndexByKey(key);
        const local_storage_key = localStorage.key(key_index);
        const myKey = CryptoJS.SHA256(key, api);
        if(myKey.toString() == local_storage_key) {
            const res = localStorage.getItem(local_storage_key);
            const decrypted = CryptoJS.AES.decrypt(res, api);
            const decrypteddata = decrypted.toString(CryptoJS.enc.Utf8);
            result = JSON.parse(decrypteddata);
        }
        return result;
    }

    getUser() {
        const data = this.decryptData('currentuser');
        let result = null;
        if(data) {
            result = data.user_details;
        }
        return result;
    }

    organisationType() {
        let user = this.getUser();
        if (user) {
            return user['org_type'];
        }

        return;
    }

    getUserRoles() {
        const data = this.decryptData('currentuser');
        let roles= null;
        if(data) {
            roles = data.user_details.roles;
        }

        return roles;
    
    }

    get isLoggedIn() {
        return this.loggedIn.asObservable();
    }
}