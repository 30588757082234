<div class="student-fee-container">

    <table class="student-fee-table" mat-table [dataSource]="student_fees">
        <!-- Item Column -->
        <ng-container matColumnDef="fee_type">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let student_fee"> {{student_fee.name}} </td>
            <td mat-footer-cell *matFooterCellDef><strong>Total</strong> </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let student_fee"> 
                <span class="student-fee-edit" contenteditable="true" (keyup)="modifyFee(student_fee.amount, $event)" (keydown.enter)="$event.target.blur()" (blur)="updateFee(student_fee, $event)">{{student_fee.amount}} </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>
                <strong class="student-fee-edit" *ngIf="!isUpdating"> {{total_fee}}</strong>
                <strong class="student-fee-edit" *ngIf="isUpdating"> {{updating_fee}}</strong>
            </td>
        </ng-container>

       
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>

</div>