import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlumniService } from 'src/app/_services/operator/alumni.service';
import { StudentInfo } from 'src/app/_models/student_info';
import { isArray } from 'util';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-alumni-search',
  templateUrl: './alumni-search.component.html',
  styleUrls: ['./alumni-search.component.scss']
})
export class AlumniSearchComponent implements OnInit {

  alumniMenuItem: string;
  alumniSearchForm: FormGroup;
  studentInfo: StudentInfo[];
  displayedColumns = [
    's_no',
    'admission_number',
    'student_name',
    'father_name',
    'mother_name',
    'date_of_admission',
    'parent_occupation',
    'parent_annual_income',
    'aadhaar_number',
    'email',
    'gender',
    'caste',
    'mother_tongue',
    'nationality',
    'religion',
    'date_of_birth',
    'identification_marks',
    'address',
    'last_studied_institution',
    'noc_issued',
    'class_of_admission',
    'class_of_removal',
    'date_of_removal',
    'tc_record_sheet_number',
    'reasons_for_removal',
    'remarks'
  ];

  constructor(private fb: FormBuilder,
              private alumniService: AlumniService,
              private globalService: GlobalService) { }

  ngOnInit() {
    this.alumniMenuItem = 'search-alumni';
    this.globalService.page_title.next('Alumni');
    this.alumniSearchForm = this.fb.group({      
      student_name: []
    });
  }

  searchAlumni() {
    this.alumniService.searchAlumni(this.alumniSearchForm.controls.student_name.value).subscribe(
      response => {
        if(isArray(response)) {
          this.studentInfo = response;
        }
      },
      error => {
        this.globalService.showError(error['error'], 'Search Alumni');
      }
    )
  }
}
