import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CourseService } from 'src/app/_services/operator/course.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-select-course',
  templateUrl: './select-course.component.html',
  styleUrls: ['./select-course.component.scss']
})
export class SelectCourseComponent implements OnInit {

  @Output() selected_course_emit = new EventEmitter<number>();

  selectCourseForm: FormGroup;
  all_courses: any;
  selected_course_id;
  entity: string;
  entityMenuItem: string;

  constructor(    
    private fb: FormBuilder,
    private courseService: CourseService,    
    
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private globalService: GlobalService
  ) { 
    this.activatedRoute.params.subscribe(
      params => {                    
        this.selected_course_id = params.course_id;        
      }
    )
  }

  ngOnInit() {
    
    if(this.router.url.indexOf('reports') !== -1) {
      this.entity = 'reports';
    } else if(this.router.url.indexOf('class-transfer') !== -1) {
      this.entity = 'class-transfer';
    }

    if(this.router.url.indexOf('promote') !== -1) {
      this.entityMenuItem = 'promote';
    } else if(this.router.url.indexOf('course-wise-fee-details') !== -1) {
      this.entityMenuItem = 'course-wise-fee-details';
    }     
    this.selectCourseForm = this.fb.group({
      selected_course : ['']
    });

    if (!localStorage.getItem('courses')) {
      this.getCourses();
    } else {
      this.all_courses = JSON.parse(localStorage.getItem('courses'));
    }

    this.navigateToActivatedRoute();
  }

  getCourses() {
    this.globalService.startLoader();
    this.courseService.getCourses().subscribe(
      response => {
        this.all_courses = response;
        localStorage.setItem('courses', JSON.stringify(response));
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Course');
      }
    )
  }

  getSelectedCourse(selected_course) {
    this.selected_course_emit.emit(selected_course.value);
    this.navigateUrl();
  }

  navigateToActivatedRoute() {    
    if(this.selected_course_id) {      
      this.selectCourseForm.get('selected_course').setValue(parseInt(this.selected_course_id));
      this.getSelectedCourse(this.selectCourseForm.controls.selected_course);
    }   
  }

  navigateUrl() {
    let selected_course_id = this.selectCourseForm.get('selected_course').value;    
    if(selected_course_id) {          
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id); 
    } 
  }

}
