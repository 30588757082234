import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlumniService } from 'src/app/_services/operator/alumni.service';
import { isArray } from 'util';
import { StudentInfo } from 'src/app/_models/student_info';
import { MatPaginator } from '@angular/material/paginator';
import { GlobalService } from 'src/app/_services/global.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-alumni-view',
  templateUrl: './alumni-view.component.html',
  styleUrls: ['./alumni-view.component.scss']
})
export class AlumniViewComponent implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  alumniMenuItem: string;
  alumniViewForm: FormGroup;
  all_batches: any;
  all_courses: any;
  all_classes: any;
  studentInfo: StudentInfo[];
  selected_batch;
  selected_course_id;
  selected_class_id;
  displayedColumns = [
    's_no',
    'admission_number',
    'student_name',
    'father_name',
    'mother_name',
    'date_of_admission',
    'parent_occupation',
    'parent_annual_income',
    'aadhaar_number',
    'email',
    'gender',
    'caste',
    'mother_tongue',
    'nationality',
    'religion',
    'date_of_birth',
    'identification_marks',
    'address',
    'last_studied_institution',
    'noc_issued',
    'class_of_admission',
    'class_of_removal',
    'date_of_removal',
    'tc_record_sheet_number',
    'reasons_for_removal',
    'remarks'
  ];

  constructor(private fb: FormBuilder,
              private alumniService: AlumniService,
              private globalService: GlobalService,
              
              private activatedRoute: ActivatedRoute,
              private location: Location) { 
                this.activatedRoute.params.subscribe(
                  params => { 
                    this.selected_batch = params.batch;
                    this.selected_course_id = params.course_id;
                    this.selected_class_id = params.class_id;                     
                  }
                )
              }

  ngOnInit() {
    this.alumniMenuItem = 'view-alumni';
    this.globalService.page_title.next('Alumni');
    this.alumniViewForm = this.fb.group({
        selected_batch : [],
        selected_course : [],
        selected_class : []
    });

    this.globalService.startLoader();
    this.alumniService.getBatches().subscribe(
      response => {        
        this.all_batches = response;        
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Alumni');
      }
    )

    this.navigateToActivatedRoute();
  }
  
  navigateToActivatedRoute() {
    
    if(this.selected_batch) {                  
      this.alumniViewForm.get('selected_batch').setValue(this.selected_batch); 
      this.getCourses(this.alumniViewForm.controls.selected_batch);       
    } 

    if(this.selected_course_id) {      
      this.alumniViewForm.get('selected_course').setValue(parseInt(this.selected_course_id));
      this.getClasses(this.alumniViewForm.controls.selected_course);
    }

    if(this.selected_class_id) {
      this.alumniViewForm.get('selected_class').setValue(parseInt(this.selected_class_id));
      this.getAlumni(this.alumniViewForm.controls.selected_class);
      
    }
  }

  getCourses(selected_batch) {  
    this.all_courses = [];    
    selected_batch = selected_batch.value;
    this.alumniViewForm.controls.selected_course.patchValue(undefined);
    this.alumniViewForm.controls.selected_class.patchValue(undefined);
    this.location.replaceState("alumni/view/" + selected_batch); 
    this.globalService.startLoader();
    this.alumniService.getCourses(selected_batch).subscribe(
      response => {
        this.all_courses = response;    
        this.globalService.stopLoader();    
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Alumni');
      }
    )
  }

  getClasses(selected_course) {
    this.all_classes = [];
    selected_course = selected_course.value;    
    this.alumniViewForm.controls.selected_class.patchValue(undefined);
    let selected_batch = this.alumniViewForm.controls.selected_batch.value;
    this.location.replaceState("alumni/view/" + selected_batch + "/" + selected_course); 
    this.globalService.startLoader();
    this.alumniService.getClasses(selected_batch, selected_course).subscribe(
      response => {
        this.all_classes = response;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Alumni');
      }
    )
  }
 
  getAlumni(selected_class) {
    const selected_batch = this.alumniViewForm.controls.selected_batch.value;
    let selected_course = this.alumniViewForm.controls.selected_course.value;    
    this.location.replaceState("alumni/view/" + selected_batch + "/" + selected_course + "/" + selected_class.value); 
    this.alumniService.getAlumni(selected_batch, selected_class.value).subscribe(
      response => {
        if(isArray(response)) {
          this.studentInfo = response;
        }        
      },
      error => {
        this.globalService.showError(error['error'], 'Get Alumni');
      }
    )
  }

}
