import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-alumni-menu',
  templateUrl: './alumni-menu.component.html',
  styleUrls: ['./alumni-menu.component.scss']
})
export class AlumniMenuComponent implements OnInit {

  @Input() alumniMenuItem: string; 
  addAlumni: boolean = false;
  viewAlumni: boolean = false;
  searchAlumni: boolean = false;
  course_id;
  class_id;
  section_id;

  constructor(private router: Router,
              private globalService: GlobalService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.getActivatedRouteParams();
    this.globalService.page_title.next('Alumni');
    if(this.alumniMenuItem == 'add-alumni') {
      this.addAlumniClick();
    } else if(this.alumniMenuItem == 'view-alumni') {
      this.viewAlumniClick();
    } else if(this.alumniMenuItem == 'search-alumni') {
      this.searchAlumniClick()
    }
  }

  getActivatedRouteParams() {
    this.activatedRoute.params.subscribe(
      params => { 
        this.course_id = params.course_id;
        this.class_id = params.class_id;
        this.section_id = params.section_id;                
      }
    )
  }

  addAlumniClick() {
    if(this.router.url.indexOf("create") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/alumni/create']);           
    }    
    this.viewNothing();    
    this.addAlumni = true;  
  }

  viewAlumniClick() {
    if(this.router.url.indexOf("view") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/alumni/view']);           
    }    
    this.viewNothing();    
    this.viewAlumni = true;      
  }

  searchAlumniClick() {
    this.router.navigateByUrl('/alumni/search');
    this.viewNothing();
    this.searchAlumni = true;  
  }

  viewNothing() {
    this.addAlumni = false;
    this.viewAlumni = false;
    this.searchAlumni = false;
  }
}
