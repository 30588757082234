import { Component, OnInit, Input } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-attendance-menu',
  templateUrl: './attendance-menu.component.html',
  styleUrls: ['./attendance-menu.component.scss']
})
export class AttendanceMenuComponent implements OnInit {

  @Input() attendanceMenuItem: string;
  addAttendance: boolean = false;
  editAttendance: boolean = false;
  deleteAttendance: boolean = false;
  course_id;
  class_id;
  section_id;

  constructor(
    private globalService: GlobalService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.globalService.page_title.next("Attendance");
   }

  ngOnInit() {
    
    this.getActivatedRouteParams();

    if(this.attendanceMenuItem == 'add-attendance') {
      this.addAttendanceClick();
    }
  }

  getActivatedRouteParams() {
    this.activatedRoute.params.subscribe(
      params => { 
        this.course_id = params.course_id;
        this.class_id = params.class_id;
        this.section_id = params.section_id;                
      }
    )
  }

  addAttendanceClick() {
    if(this.router.url.indexOf("add") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/attendance/add']);           
    } 
    this.viewNothing();    
    this.addAttendance = true;  
  }

  viewNothing() {
    this.addAttendance = false;
    this.editAttendance = false;
    this.deleteAttendance = false;
  }

}
