import { Component, OnInit, OnChanges } from '@angular/core';
import { SectionService } from 'src/app/_services/operator/section.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PaymentService } from 'src/app/_services/operator/payment.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { searchPayment } from 'src/app/_models/search_payment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-search-payment',
  templateUrl: './search-payment.component.html',
  styleUrls: ['./search-payment.component.scss']
})
export class SearchPaymentComponent implements OnInit {

  public branch_id: Number;
  searchPaymentForm: FormGroup;
  paymentMenuItem: string;
  search_payment_results: any;
  updatePayment: boolean = false;
  student_id: Number;
  paymentInfo: any;
  displayedColumns: string[] = ['s_no', 'student_name','amount', 'receipt_date', 'fee_type', 'action']

  constructor(private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private paymentService: PaymentService,
    private snackBar: MatSnackBar,
    
    private globalService: GlobalService) {

    this.searchPaymentForm = this.fb.group({
      receipt_number: []
    })

    this.paymentService.payment_result.subscribe(
      data => {
        this.searchPayments();
      }
    )
  }

  ngOnInit() {
    this.paymentMenuItem = 'search-payment';
  }

  searchPayments() {
    this.updatePayment = false;
    let receipt_number = this.searchPaymentForm.get('receipt_number').value
    if(receipt_number !== null) {
      this.globalService.startLoader();
      this.paymentService.getPaymentsByReceiptNumber(receipt_number).subscribe(
        response => {
          this.search_payment_results = response;
          this.globalService.stopLoader();
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], 'Search Payment');
        }
      )
    }    
  }

  editPayment(payment_id: Number, student_id: Number) {
    this.student_id = student_id;
    this.updatePayment = true;
    for(var i=0;i<this.search_payment_results.length;i++) {
      if(this.search_payment_results[i]['id'] == payment_id) {        
        this.paymentInfo = this.search_payment_results[i];
      }
    }
  }

  removePayment(payment_id: number) {
    this.globalService.startLoader();
    this.paymentService.deletePayment(payment_id).subscribe(
      response => {
        this.globalService.showMessage(response['message'], 'Delete');
        this.globalService.stopLoader();
        this.searchPayments();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Delete');
      }
    )
  }

}
