import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { StudentJoiningDetails } from 'src/app/_models/student_joining_details';
import { BehaviorSubject } from 'rxjs';
import { StudentPersonalDetails } from 'src/app/_models/student_personal_details';
import { StudentOtherDetails } from 'src/app/_models/student_other_details';
import { StudentRemovalDetails } from 'src/app/_models/student_removal_details';
@Injectable({
  providedIn: 'root'
})
export class StudentService {

  private studentsURL = environment.API_URL + '/students';
  private studentDetailsURL = environment.API_URL + '/student_details';
  public studentInfoUpdated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public studentsView: BehaviorSubject<string> = new BehaviorSubject<string>('listView');

  studentsViewObservable = this.studentsView.asObservable();

  constructor(private httpClient: HttpClient) { }

  getStudents() {
    return this.httpClient.get(this.studentsURL);
  }

  getStudentsBySection(section_id: number) {
    return this.httpClient.get(this.studentsURL + '/section/' + section_id);
  }

  getStudentsBasicInfoBySection(section_id: number) {
    return this.httpClient.get(this.studentsURL + '/basic_info/' + section_id);
  }

  addStudent(data) {
    return this.httpClient.post(this.studentsURL, data);
  }

  editStudent(student_id: number, data: any) {
    return this.httpClient.put(this.studentsURL + '/' + student_id, data);
  }

  getStudentById(student_id: number) {
    return this.httpClient.get(this.studentsURL+'ById/'+student_id);
  }

  getStudentBasicInfo(student_id: number) {
    return this.httpClient.get(this.studentDetailsURL+'/basic/'+student_id);
  }

  getStudentDetails(student_id: number) {
    return this.httpClient.get(this.studentDetailsURL+'/'+student_id);
  }

  searchStudent(search_name) {
    return this.httpClient.get(this.studentsURL + '/search/' +search_name);
  }

  uploadStudentImage(data) {
    return this.httpClient.post(this.studentsURL + '/uploadImage', data, {
      reportProgress: true,
      observe: 'events'
    });
  }

  deleteStudent(student_id: number) {
    return this.httpClient.delete(this.studentsURL + '/' + student_id);
  }

  saveStudentJoiningDetails(data: StudentJoiningDetails) {
    return this.httpClient.post(this.studentDetailsURL + '/joining', data);
  }

  saveStudentPersonalDetails(data: StudentPersonalDetails) {
    return this.httpClient.post(this.studentDetailsURL + '/personal', data);
  }

  saveStudentOtherDetails(data: StudentOtherDetails) {
    return this.httpClient.post(this.studentDetailsURL + '/other', data);
  }

  saveStudentRemovalDetails(data: StudentRemovalDetails) {
    return this.httpClient.post(this.studentDetailsURL + '/removal', data);
  }

  getFields() {
    return this.httpClient.get(this.studentDetailsURL + '/fields');
  }
}
