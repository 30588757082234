import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import * as CryptoJS from 'crypto-js';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const key_index = this.authenticationService.getIndexByKey('currentuser');
        const local_storage_key = localStorage.key(key_index);
        if(local_storage_key !== null) {
            const valid = this.validateKey(local_storage_key);
            if(!valid) {
                localStorage.clear();
                this.router.navigate(['/500']);
            }
            return valid;
        }

        this.router.navigate(['/login']);
        return false;
    }

    validateKey(local_storage_key) {
        const api = 'response';
        const myKey = CryptoJS.SHA256('currentuser', api);
        if(local_storage_key == myKey.toString()) {
            let res: string;
            res = localStorage.getItem(local_storage_key);
            const decrypted = CryptoJS.AES.decrypt(res, api);
            const decrypted_data = decrypted.toString(CryptoJS.enc.Utf8);
            if(!decrypted_data) {
                return false;
            }
            const data = JSON.parse(decrypted_data);
            if(data.token) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    sessionExpired() {
        localStorage.clear();
    }
    
}