import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {AuthGuard} from './_helpers/auth.guard';
import {LogoutComponent} from './common/logout/logout.component';
import {HomeComponent as DashboardHomeComponent} from './dashboard/home/home.component';
import {ViewStudentsComponent} from './student/view-students/view-students.component';
import {AddStudentComponent} from './student/add-student/add-student.component';
import {CategoriesComponent} from './reports/categories/categories.component';
import {ClassWiseFeeDetailsComponent} from './reports/class-wise-fee-details/class-wise-fee-details.component';
import {SectionWiseFeeDetailsComponent} from './reports/section-wise-fee-details/section-wise-fee-details.component';
import {AlumniCreateComponent} from './alumni/alumni-create/alumni-create.component';
import {AlumniViewComponent} from './alumni/alumni-view/alumni-view.component';
import {AlumniSearchComponent} from './alumni/alumni-search/alumni-search.component';
import {AlumniMenuComponent} from './alumni/alumni-menu/alumni-menu.component';
import {PromoteClassComponent} from './common/promote-class/promote-class.component';
import {CourseWiseFeeDetailsComponent} from './reports/course-wise-fee-details/course-wise-fee-details.component';
import {StudentMenuComponent} from './student/student-menu/student-menu.component';
import {HomeComponent} from './common/home/home.component';
import {SearchStudentComponent} from './student/search-student/search-student.component';
import {PaymentMenuComponent} from './payments/payment-menu/payment-menu.component';
import {TrackPaymentsComponent} from './payments/track-payments/track-payments.component';
import {UpdatePaymentComponent} from './payments/update-payment/update-payment.component';
import {SearchPaymentComponent} from './payments/search-payment/search-payment.component';
import {AttendanceMenuComponent} from './attendance/attendance-menu/attendance-menu.component';
import {AddAttendanceComponent} from './attendance/add-attendance/add-attendance.component';
import {TransactionFormComponent} from './transactions/transaction-form/transaction-form.component';
import {TransactionsListComponent} from './transactions/transactions-list/transactions-list.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {
    path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'home', component: DashboardHomeComponent}
    ]
  },
  {
    path: 'students', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'menu', component: StudentMenuComponent},
      {path: 'create', component: AddStudentComponent},
      {path: 'create/:course_id', component: AddStudentComponent},
      {path: 'create/:course_id/:class_id', component: AddStudentComponent},
      {path: 'create/:course_id/:class_id/:section_id', component: AddStudentComponent},
      {path: 'view', component: ViewStudentsComponent},
      {path: 'view/:course_id', component: ViewStudentsComponent},
      {path: 'view/:course_id/:class_id', component: ViewStudentsComponent},
      {path: 'view/:course_id/:class_id/:section_id', component: ViewStudentsComponent},
      {path: 'search', component: SearchStudentComponent}
    ]
  },
  {
    path: 'payments', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'menu', component: PaymentMenuComponent},
      {path: 'track', component: TrackPaymentsComponent},
      {path: 'track/:course_id', component: TrackPaymentsComponent},
      {path: 'track/:course_id/:class_id', component: TrackPaymentsComponent},
      {path: 'track/:course_id/:class_id/:section_id', component: TrackPaymentsComponent},
      {path: 'update', component: UpdatePaymentComponent},
      {path: 'update/:course_id', component: UpdatePaymentComponent},
      {path: 'update/:course_id/:class_id', component: UpdatePaymentComponent},
      {path: 'update/:course_id/:class_id/:section_id', component: UpdatePaymentComponent},
      {path: 'search', component: SearchPaymentComponent}
    ]
  },
  {
    path: 'attendance', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'menu', component: AttendanceMenuComponent},
      {path: 'add', component: AddAttendanceComponent},
      {path: 'add/:course_id', component: AddAttendanceComponent},
      {path: 'add/:course_id/:class_id', component: AddAttendanceComponent},
      {path: 'add/:course_id/:class_id/:section_id', component: AddAttendanceComponent}
    ]
  },
  {
    path: 'reports', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'categories', component: CategoriesComponent},
      {path: 'class-wise-fee-details', component: ClassWiseFeeDetailsComponent},
      {path: 'class-wise-fee-details/:course_id', component: ClassWiseFeeDetailsComponent},
      {path: 'class-wise-fee-details/:course_id/:class_id', component: ClassWiseFeeDetailsComponent},
      {path: 'section-wise-fee-details', component: SectionWiseFeeDetailsComponent},
      {path: 'section-wise-fee-details/:course_id', component: SectionWiseFeeDetailsComponent},
      {path: 'section-wise-fee-details/:course_id/:class_id', component: SectionWiseFeeDetailsComponent},
      {path: 'section-wise-fee-details/:course_id/:class_id/:section_id', component: SectionWiseFeeDetailsComponent},
      {path: 'course-wise-fee-details', component: CourseWiseFeeDetailsComponent},
      {path: 'course-wise-fee-details/:course_id', component: CourseWiseFeeDetailsComponent}
    ]
  },
  {
    path: 'transactions', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'view', component: TransactionsListComponent},
      {path: 'view/:from_date/:to_date/:income_type/:tag', component: TransactionsListComponent},
      {path: 'create', component: TransactionFormComponent}
    ]
  },
  {
    path: 'alumni', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'menu', component: AlumniMenuComponent},
      {path: 'view', component: AlumniViewComponent},
      {path: 'view/:batch', component: AlumniViewComponent},
      {path: 'view/:batch/:course_id', component: AlumniViewComponent},
      {path: 'view/:batch/:course_id/:class_id', component: AlumniViewComponent},
      {path: 'create', component: AlumniCreateComponent},
      {path: 'create/:course_id', component: AlumniCreateComponent},
      {path: 'create/:course_id/:class_id', component: AlumniCreateComponent},
      {path: 'create/:course_id/:class_id/:section_id', component: AlumniCreateComponent},
      {path: 'search', component: AlumniSearchComponent}
    ]
  },
  {
    path: 'class-transfer', component: HomeComponent, canActivate: [AuthGuard],
    children: [
      {path: 'promote', component: PromoteClassComponent},
      {path: 'promote/:course_id', component: PromoteClassComponent}
    ]
  },

  {path: 'logout', component: LogoutComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
