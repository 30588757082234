import {Component, Inject, OnInit} from '@angular/core';
import {GlobalService} from 'src/app/_services/global.service';
import {ReportService} from 'src/app/_services/common/report.service';
import {PaymentsBySection} from 'src/app/_models/payments_by_section';
import {FormBuilder, FormGroup} from '@angular/forms';
import 'rxjs/add/operator/map';
import {MatOption} from '@angular/material/core';
import {MatSelectChange} from '@angular/material/select';
import {PrintService} from 'src/app/_services/operator/print.service';
import {FeeService} from 'src/app/_services/operator/fee.service';
import {SmsService} from '../../_services/operator/sms.service';

@Component({
  selector: 'app-section-wise-fee-details',
  templateUrl: './section-wise-fee-details.component.html',
  styleUrls: ['./section-wise-fee-details.component.scss']
})
export class SectionWiseFeeDetailsComponent implements OnInit {

  section_id: number;
  payments_by_section: PaymentsBySection[];
  fee_types: any;
  feeTypeSelectionForm: FormGroup;
  result: boolean = false;
  displayedColumns = ['s_no', 'student_name', 'father_name', 'mobile_number', 'committed_fee', 'paid_fee', 'pending_fee'];
  selectedSMSStudents = [];

  constructor(private globalService: GlobalService,
              private reportsService: ReportService,
              private feeService: FeeService,
              private printService: PrintService,
              private fb: FormBuilder,
              private smsService: SmsService) {
    this.feeTypeSelectionForm = this.fb.group({
      fee_type_id: ['total_fee']
    });
  }

  ngOnInit() {
    this.globalService.page_title.next('Section Wise Fee Particulars');
    this.feeService.getAllFeeTypes().subscribe(
      response => {
        this.fee_types = response;
      },
      error => {
        this.globalService.showError(error.error, 'Get Fee types');
      }
    );
  }

  selectedSection(section_id: number) {
    this.section_id = section_id;
    if (this.section_id) {
      this.feeTypeSelectionForm.controls.fee_type_id.setValue('total_fee');
      this.getSectionWisePayments(section_id);
    }
  }

  getSectionWisePayments(section_id: number) {
    this.globalService.startLoader();
    this.selectedSMSStudents = [];
    this.reportsService.getSectionWisePayments(section_id).subscribe(
      response => {
        this.payments_by_section = response['section_payments'];
        this.result = true;
        this.payments_by_section.forEach(student => {
          student.all_total_student_fee = student.total_student_fee;
          student.all_total_paid_fee = student.total_paid_fee;
          student.all_total_pending_fee = student.total_pending_fee;
        });

        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Report')
      }
    );
  }

  getFeeTypeIdSelect(fee_type: MatSelectChange) {
    const selected_fee_type = {
      text: (fee_type.source.selected as MatOption).viewValue,
      value: fee_type.source.value
    };
    const fee_type_id = selected_fee_type.value;
    let student_fee;
    this.payments_by_section.forEach(student => {
      if (fee_type_id == 'total_fee') {
        student.all_total_student_fee = student.total_student_fee;
        student.all_total_paid_fee = student.total_paid_fee;
        student.all_total_pending_fee = student.total_pending_fee;
      } else {
        student_fee = student.student_fee.filter(sf => sf.fee_type_id == fee_type_id)[0];
        if (student_fee) {
          student.all_total_student_fee = student_fee.amount;
          student.all_total_paid_fee = student_fee.paid;
          student.all_total_pending_fee = student_fee.pending;
        } else {
          student.all_total_student_fee = '0';
          student.all_total_paid_fee = '0';
          student.all_total_pending_fee = '0';
        }
      }
    });
  }

  /** Gets the total student fee of all students. */
  getTotalCommittedFee() {
    if (this.result) {
      return this.payments_by_section.map(pbs => parseInt(pbs.all_total_student_fee)).reduce((acc, value) => acc + value, 0);
    }
  }

  /** Gets the total paid fee of all students. */
  getTotalPaidFee() {
    if (this.result) {
      return this.payments_by_section.map(pbs => parseInt(pbs.all_total_paid_fee)).reduce((acc, value) => acc + value, 0);
    }
  }

  /** Gets the total pending fee of all students. */
  getTotalPendingFee() {
    if (this.result) {
      return this.payments_by_section.map(pbs => parseInt(pbs.all_total_pending_fee)).reduce((acc, value) => acc + value, 0);
    }
  }

  print() {
    const fee_type_id = this.feeTypeSelectionForm.get('fee_type_id').value;
    this.printService
      .sectionWiseFeePrint(this.section_id, fee_type_id)
      .subscribe(
        response => {
          this.printService.open(response['url']);
        },
        error => console.log(error)
      );
  }

  sendSmsFeeReminder() {

    this.payments_by_section.forEach((studentPayment) => {
      this.selectedSMSStudents.push({
        studentId: studentPayment.id,
        studentName: studentPayment.student_name,
        pendingFee: studentPayment.total_pending_fee,
        mobileNumber: studentPayment.mobile_number,
      });
    });

    this.globalService.startLoader();
    this.smsService.sendSMSFeeReminder(this.selectedSMSStudents).subscribe(
      response => {
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Status');
      }, error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Status');
      }
    );

    console.log(this.selectedSMSStudents);
  }
}
