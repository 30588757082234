<div class="payment-update-form">
    <form class="ml-3" [formGroup]="updatePaymentForm">
        <mat-form-field>
            <input formControlName="payment_id" type="hidden"/>
            <input matInput formControlName="receipt_number" placeholder="Receipt Number">
            <mat-icon matSuffix>receipt</mat-icon>
        </mat-form-field><br />

        <mat-form-field style="margin-right:25px;width: 100%;margin-bottom:-8px">
            <mat-select formControlName="fee_type_id" placeholder="Select Fee Type" required
                (selectionChange)="getFeeTypeIdSelect($event)">
                <mat-option value="">Total Fee</mat-option>
                <mat-option *ngFor="let fee_type of student_fee_types" [value]="fee_type.fee_type_id">
                    {{fee_type.name}}</mat-option>
            </mat-select>
        </mat-form-field><br />
        <mat-form-field>
            <input matInput formControlName="receipt_date" [matDatepicker]="picker" placeholder="Received on" required>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field><br />        

        <mat-form-field>
            <input matInput formControlName="amount" placeholder="Amount" required>
            <mat-icon matSuffix>money</mat-icon>
        </mat-form-field><br />

        <mat-form-field>
            <input matInput formControlName="paid_by" placeholder="Received From" required>
            <mat-icon matSuffix>person_outline</mat-icon>
        </mat-form-field><br />

        <mat-form-field>
            <input matInput formControlName="collected_by" placeholder="Collected By" required>
            <mat-icon matSuffix>person</mat-icon>
        </mat-form-field><br /><br />

        <div class="text-center">
            <button color="custom-color" mat-button (click)="savePayment()">Update</button>
        </div>
    
        <br /><br />
    </form>
</div>