import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AttendanceService {

  private attendanceURL = environment.API_URL + '/attendance';

  constructor(private httpClient: HttpClient) { }

  getSessions() {
    return this.httpClient.get(this.attendanceURL + '/sessions');
  }

  getStudents(section_id) {
    return this.httpClient.get(this.attendanceURL + '/students/' + section_id);
  }

  saveAttendance(attendance_body) {
    return this.httpClient.post(this.attendanceURL,  attendance_body);
  }

  getAttendance(section_id, session_id, date) {
    return this.httpClient.get(this.attendanceURL + '/students/' + section_id + '/' + session_id + '/' + date);
  }

  deleteAttendance(attendance_id: number, section_id: number) {
    return this.httpClient.delete(this.attendanceURL + '/' + attendance_id + '/' + section_id);
  }
}
