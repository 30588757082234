import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    let route = this.router.url;    
    this.setTitle(route);
  }

  setTitle(route: string) {

    switch(route) {
      case '/dashboard'       : this.globalService.page_title.next('Dashboard');
                                break;
      case '/students'        : this.globalService.page_title.next('Students');
                                break;
      case '/payments'        : this.globalService.page_title.next('Payments');
                                break;
      case '/transactions'    : this.globalService.page_title.next('Income & Expense');
                                break;
      case '/attendance'      : this.globalService.page_title.next('Attendance');
                                break;
      case '/reports'         : this.globalService.page_title.next('Reports');
                                break;
      case '/alumni'          : this.globalService.page_title.next('Alumni');
                                break;
      case '/class-transfer'  : this.globalService.page_title.next('Class Transfer');
                                break;
    }    
  }

}
