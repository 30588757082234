<mat-toolbar class="menu-horizontal">
    <div class="d-flex align-center" style="display: inline-block;">
        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': trackPayments}" (click)="trackPaymentsClick()">            
            <span>TRACK PAYMENTS</span><br />
        </div>

        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': updatePayment}" (click)="updatePaymentClick()">            
            <span>UPDATE PAYMENT</span><br />
        </div>
       
        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': searchPayment}" (click)="searchPaymentClick()">           
            <span>SEARCH PAYMENT</span><br />
        </div>
    </div>
</mat-toolbar>