<div class="student-details-form-container">
    <form [formGroup]="editStudentInfoFormGroup">
        <div class="student-details-form" style="opacity:1">
            <h3 class="mt-5">Student Info</h3>
            <mat-form-field class="mt-2" >
                <input matInput placeholder="Student Name" formControlName="student_name">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Father Name" formControlName="father_name">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Mobile Number" formControlName="mobile_number">
            </mat-form-field>

            <div class="example-header-image">
                <img class="student-image" src="{{previewUrl}}" alt="Student image"
                    (click)="studentImageInput.click()">
                <input #studentImageInput type="file" formControlName="student_image" (change)="onStudentImageInput($event)"
                    style="display:none;" />
            </div>

            <div class="d-flex flex-row-reverse">
                <button mat-button color="custom-color" (click)="updateStudentInfo()">Save</button>
            </div>         
        </div>
    </form>
</div>