
<div class="student-info-container d-flex" *ngIf="student_info">
  <div mat-card-avatar class="student-image" [ngStyle]="{'background-image': 'url(' + student_info.student_image + ')'}"></div>
  <div class="student-info">
      <strong>{{student_info.student_name}}</strong> <br/> 
      s/o {{student_info.father_name}} <br/>
      Course: {{student_info.course_name}} || Class: {{student_info.class_name}} || Section: {{student_info.section_name}}
  </div>
</div>

<br/>

<mat-divider></mat-divider>


<mat-tab-group [selectedIndex]="tab_index">
  <mat-tab label="Profile">
    <app-edit-student [student_id]="student_id" [studentInfo]="student_info"></app-edit-student>
    <app-student-details [student_id]="student_id" (completed)="isStudentDetailsCompleted($event)"></app-student-details>
  </mat-tab>

  <mat-tab label="Fee Particulars">
    <div style="margin-top:50px;" >
      <app-student-fee [student_id]="student_id"></app-student-fee>
    </div>
  </mat-tab>

  <mat-tab label="Payments" class="payments-tab">
    <app-student-payments [student_id]="student_id"></app-student-payments>
  </mat-tab>

  <mat-tab label="Attendance"> Attendance </mat-tab>  

  <mat-tab label="Marks"> Marks </mat-tab>
  
</mat-tab-group>