import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CourseService } from 'src/app/_services/operator/course.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-select-class',
  templateUrl: './select-class.component.html',
  styleUrls: ['./select-class.component.scss']
})
export class SelectClassComponent implements OnInit {

  @Output() selected_class_emit = new EventEmitter<number>();

  selectClassForm: FormGroup;
  all_courses: any;
  all_classes: any;
  entity: string;
  entityMenuItem: string;
  selected_course_id;
  selected_class_id;

  constructor(    
    private fb: FormBuilder,
    private courseService: CourseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private globalService: GlobalService    
  ) {
      this.activatedRoute.params.subscribe(
        params => {                    
          this.selected_course_id = params.course_id;
          this.selected_class_id = params.class_id;          
        }
      )
   }

  ngOnInit() {

    if(this.router.url.indexOf('reports') !== -1) {
      this.entity = 'reports';
    }

    if(this.router.url.indexOf('class-wise-fee-details') !== -1) {
      this.entityMenuItem = 'class-wise-fee-details';
    }     

    this.selectClassForm = this.fb.group({
      selected_course : [''],
      selected_class : ['']
    });
    
    if (!localStorage.getItem('courses')) {
      this.getCourses();
    } else {
      this.all_courses = JSON.parse(localStorage.getItem('courses'));
    }

    this.navigateToActivatedRoute();
  }

  getCourses() {
    this.globalService.startLoader();    
    this.courseService.getCourses().subscribe(
      response => {
        this.all_courses = response;
        localStorage.setItem('courses', JSON.stringify(response));
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Course');
      }
    )
  }

  navigateToActivatedRoute() {
    
    if(this.selected_course_id) {      
      this.selectClassForm.get('selected_course').setValue(parseInt(this.selected_course_id));
      this.getSelectedCourse(this.selectClassForm.controls.selected_course);
    }

    if(this.selected_class_id) {                  
      this.selectClassForm.get('selected_class').setValue(parseInt(this.selected_class_id));
      this.getSelectedClass(this.selectClassForm.controls.selected_class);
      
    }
   
  }

  getSelectedCourse(selected_course) {
    selected_course = selected_course.value;
    if (this.all_courses) {
      this.all_classes = this.all_courses
                            .filter(course => course.id === selected_course)[0]
                            .classes;
    }
    this.navigateUrl();
  }

  getSelectedClass(selected_class) {
    this.selected_class_emit.emit(selected_class.value);
    this.navigateUrl();
  }

  navigateUrl() {
    let selected_course_id = this.selectClassForm.get('selected_course').value;
    let selected_class_id = this.selectClassForm.get('selected_class').value;    
    if(selected_class_id) {      
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id + "/" + selected_class_id); 
    } else if(selected_course_id) {          
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id); 
    } 
  }
}
