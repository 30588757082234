import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';
import { CourseService } from 'src/app/_services/operator/course.service';
import { PrintService } from 'src/app/_services/operator/print.service';
import { StudentService } from 'src/app/_services/operator/student.service';

@Component({
  selector: 'app-section-wise-students-list',
  templateUrl: './section-wise-students-list.component.html',
  styleUrls: ['./section-wise-students-list.component.scss']
})
export class SectionWiseStudentsListComponent implements OnInit {

  studentFilterForm: FormGroup;
  all_courses: any;
  all_classes: any;
  all_sections: any;
  all_fields: any;
  selected_course_id: number;
  selected_class_id: number;

  constructor(private fb: FormBuilder,
    private globalService: GlobalService,
    private courseService: CourseService,
    private studentService: StudentService,
    private printService: PrintService) { }

  ngOnInit(): void {
    this.studentFilterForm = this.fb.group({
      selected_course : [],
      selected_class : [],
      selected_sections : [],
      selected_fields: []
    });

    if (!localStorage.getItem('courses')) {
      this.getCourses();
    } else {
      this.all_courses = JSON.parse(localStorage.getItem('courses'));
    }

  }

  getCourses() {
    this.globalService.startLoader();
    this.courseService.getCourses().subscribe(
      response => {
        this.all_courses = response;
        localStorage.setItem('courses', JSON.stringify(response));
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Course');
      }
    )
  }

  getClasses(selected_course) {
    this.studentFilterForm.patchValue({
      selected_class: undefined,
      selected_section: undefined
    });       
    if (this.all_courses) {
      this.all_classes = this.all_courses
                            .filter(course => course.id === selected_course.value)[0]
                            .classes;
    }
  }

  getSections(selected_class) {      
    this.studentFilterForm.patchValue({
      selected_section: undefined
    });

    let classes = this.all_classes
                      .filter(clas => clas.id === selected_class.value);

    if (classes.length > 0) {
      this.all_sections = classes[0].sections;
      this.getStudentFields();
    }

  }

  getStudentFields() {
    this.globalService.startLoader();
    this.studentService.getFields().subscribe(
      fields => {
        this.all_fields = fields;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
      }
    )
  }

  generate() {
    const formData = {
      section_ids: this.studentFilterForm.get('selected_sections').value,
      column_names: this.studentFilterForm.get('selected_fields').value,
    }

    this.printService.sectionWiseStudentsList(formData).subscribe(
      response => {
        this.printService.open(response['url']);
      },
      error => console.log(error)
    );
  }

}
