import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LoginComponent} from './login/login.component';
import {SidenavComponent} from './common/sidenav/sidenav.component';
import {MyMaterialModule} from './material.module';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ErrorInterceptor} from './_helpers/error.interceptor';
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LogoutComponent} from './common/logout/logout.component';
import {HomeComponent as DashboardHomeComponent} from './dashboard/home/home.component';
import {AddStudentComponent} from './student/add-student/add-student.component';
import {ViewStudentsComponent} from './student/view-students/view-students.component';
import {SelectSectionComponent} from './common/select-section/select-section.component';
import {EditStudentFeeComponent} from './fee-particulars/edit-student-fee/edit-student-fee.component';
import {ViewStudentDialogComponent} from './student/view-student-dialog/view-student-dialog.component';
import {StudentDetailsComponent} from './student/student-details/student-details.component';
import {StudentFeeComponent} from './fee-particulars/student-fee/student-fee.component';
import {AddPaymentComponent} from './payments/add-payment/add-payment.component';
import {PaymentFormComponent} from './payments/payment-form/payment-form.component';
import {PreviousPaymentsComponent} from './payments/previous-payments/previous-payments.component';
import {UpdatePaymentComponent} from './payments/update-payment/update-payment.component';
import {UpdatePaymentFormComponent} from './payments/update-payment-form/update-payment-form.component';
import {SearchPaymentComponent} from './payments/search-payment/search-payment.component';
import {SearchStudentComponent} from './student/search-student/search-student.component';
import {StudentsCardViewComponent} from './student/students-card-view/students-card-view.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {StudentPaymentsComponent} from './payments/student-payments/student-payments.component';
import {EditStudentComponent} from './student/edit-student/edit-student.component';
import {NgxUiLoaderConfig, NgxUiLoaderModule, POSITION, SPINNER} from 'ngx-ui-loader';
import {StudentsListViewComponent} from './student/students-list-view/students-list-view.component';
import {HomeComponent} from './reports/home/home.component';
import {CategoriesComponent} from './reports/categories/categories.component';
import {ClassWiseFeeDetailsComponent} from './reports/class-wise-fee-details/class-wise-fee-details.component';
import {SectionWiseFeeDetailsComponent} from './reports/section-wise-fee-details/section-wise-fee-details.component';
import {AlumniCreateComponent} from './alumni/alumni-create/alumni-create.component';
import {AlumniMenuComponent} from './alumni/alumni-menu/alumni-menu.component';
import {AlumniViewComponent} from './alumni/alumni-view/alumni-view.component';
import {AlumniSearchComponent} from './alumni/alumni-search/alumni-search.component';
import {PromoteClassComponent} from './common/promote-class/promote-class.component';
import {SelectCourseComponent} from './common/select-course/select-course.component';
import {SelectClassComponent} from './common/select-class/select-class.component';
import {CourseWiseFeeDetailsComponent} from './reports/course-wise-fee-details/course-wise-fee-details.component';
import {StudentMenuComponent} from './student/student-menu/student-menu.component';
import {HomeComponent as CommonHomeComponent} from './common/home/home.component';
import {PaymentMenuComponent} from './payments/payment-menu/payment-menu.component';
import {TrackPaymentsComponent} from './payments/track-payments/track-payments.component';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {AttendanceMenuComponent} from './attendance/attendance-menu/attendance-menu.component';
import {AddAttendanceComponent} from './attendance/add-attendance/add-attendance.component';
import {EditStudentFeeDialogComponent} from './payments/edit-student-fee-dialog/edit-student-fee-dialog.component';
import {TransactionFormComponent} from './transactions/transaction-form/transaction-form.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {TransactionsListComponent} from './transactions/transactions-list/transactions-list.component';
import {TransactionMenuComponent} from './transactions/transaction-menu/transaction-menu.component';
import {TransactionCardComponent} from './transactions/transaction-card/transaction-card.component';
import {
  SectionWiseStudentsListComponent
} from './reports/section-wise-students-list/section-wise-students-list.component';

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: `rgba(12, 80, 219, 0.98)`,
  bgsOpacity: 1,
  bgsPosition: POSITION.bottomRight,
  bgsSize: 40,
  fgsSize: 100,
  bgsType: SPINNER.cubeGrid,
  fgsType: SPINNER.foldingCube,
  fgsColor: `#000080`,
  fgsPosition: POSITION.centerCenter
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidenavComponent,
    LogoutComponent,
    DashboardHomeComponent,
    AddStudentComponent,
    ViewStudentsComponent,
    SelectSectionComponent,
    EditStudentFeeComponent,
    ViewStudentDialogComponent,
    StudentDetailsComponent,
    StudentFeeComponent,
    AddPaymentComponent,
    PaymentFormComponent,
    PreviousPaymentsComponent,
    UpdatePaymentComponent,
    UpdatePaymentFormComponent,
    SearchPaymentComponent,
    SearchStudentComponent,
    StudentsCardViewComponent,
    StudentPaymentsComponent,
    EditStudentComponent,
    StudentsListViewComponent,
    HomeComponent,
    CategoriesComponent,
    ClassWiseFeeDetailsComponent,
    SectionWiseFeeDetailsComponent,
    AlumniCreateComponent,
    AlumniMenuComponent,
    AlumniViewComponent,
    AlumniSearchComponent,
    PromoteClassComponent,
    SelectCourseComponent,
    SelectClassComponent,
    CourseWiseFeeDetailsComponent,
    StudentMenuComponent,
    CommonHomeComponent,
    PaymentMenuComponent,
    TrackPaymentsComponent,
    AttendanceMenuComponent,
    AddAttendanceComponent,
    EditStudentFeeDialogComponent,
    TransactionFormComponent,
    TransactionsListComponent,
    TransactionMenuComponent,
    TransactionCardComponent,
    SectionWiseStudentsListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MyMaterialModule,
    MDBBootstrapModule.forRoot(),
    HttpClientModule,
    ReactiveFormsModule,
    NgxMatFileInputModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    FormsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {useUtc: true}
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB'
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
