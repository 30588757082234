<app-alumni-menu [alumniMenuItem]="alumniMenuItem"></app-alumni-menu>

<app-select-section (section_id_emit)="getStudentsBySection($event)"></app-select-section>


<div class="page-content-view-port mat-elevation-z8" *ngIf="studentBasicDetails">
    <table mat-table [dataSource]="studentBasicDetails" matSort>
  
      <!-- S.No Column -->
      <ng-container matColumnDef="s_no">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> S.No </th>
        <td mat-cell *matCellDef="let studentBasicDetails; let i=index"> {{i+1}} </td>
      </ng-container>
  
      <!-- Student Name Column -->
      <ng-container matColumnDef="student_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Student Name </th>
        <td mat-cell *matCellDef="let studentBasicDetails"> {{studentBasicDetails.student_name}} </td>
      </ng-container>
  
      <!-- Father Name Column -->
      <ng-container matColumnDef="father_name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Father Name </th>
        <td mat-cell *matCellDef="let studentBasicDetails"> {{studentBasicDetails.father_name}} </td>
      </ng-container>
  
      <!-- Mobile Number Column -->
      <ng-container matColumnDef="mobile_number">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile Number </th>
        <td mat-cell *matCellDef="let studentBasicDetails"> {{studentBasicDetails.mobile_number}} </td>
      </ng-container>

      <!-- Batch Column -->
      <ng-container matColumnDef="batch">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Batch </th>
        <td mat-cell *matCellDef="let studentBasicDetails; let i=index">
            <mat-select placeholder="Select Batch" [(ngModel)]="batch[i]">
                <mat-option [value]="2000">2000</mat-option>
                <mat-option [value]="2001">2001</mat-option>
                <mat-option [value]="2002">2002</mat-option>
                <mat-option [value]="2003">2003</mat-option>
                <mat-option [value]="2004">2004</mat-option>
                <mat-option [value]="2005">2005</mat-option>
                <mat-option [value]="2006">2006</mat-option>
                <mat-option [value]="2007">2007</mat-option>
                <mat-option [value]="2008">2008</mat-option>
                <mat-option [value]="2009">2009</mat-option>
                <mat-option [value]="2010">2010</mat-option>
                <mat-option [value]="2011">2011</mat-option>
                <mat-option [value]="2012">2012</mat-option>
                <mat-option [value]="2013">2013</mat-option>
                <mat-option [value]="2014">2014</mat-option>
                <mat-option [value]="2015">2015</mat-option>
                <mat-option [value]="2016">2016</mat-option>
                <mat-option [value]="2017">2017</mat-option>
                <mat-option [value]="2018">2018</mat-option>
                <mat-option [value]="2019">2019</mat-option>
                <mat-option [value]="2020">2020</mat-option>
                <mat-option [value]="2021">2021</mat-option>
            </mat-select>
        </td>
      </ng-container>

      <!-- Mobile Number Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
        <td mat-cell *matCellDef="let studentBasicDetails;let i=index"> 
            <button mat-button color="custom-color" (click)="addAsAlumni(i, studentBasicDetails, batch[i])" style="padding:0px 15px 0px 15px">Add as Alumni</button>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let studentBasicDetails; columns: displayedColumns;"></tr>
  
      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
      </tr>
    </table>
  
    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
  </div>