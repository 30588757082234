import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StudentService } from 'src/app/_services/operator/student.service';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-update-payment',
  templateUrl: './update-payment.component.html',
  styleUrls: ['./update-payment.component.scss']
})
export class UpdatePaymentComponent implements OnInit, OnChanges {
  
  section_id: number;
  students: any;  
  paymentMenuItem: string;
  student_id: number;
  studentSelected: boolean = false;
  fee_type_id: number;
  payment_saved: boolean = false;
  paymentInfo: any;
  viewStudents: boolean = true;
  viewPreviousPayments: boolean = false;
  viewPaymentForm: boolean = false;
  student_details: any;
  
  constructor(
    
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
    public globalService: GlobalService
  ) {
    this.activatedRoute.params.subscribe(
      params => {                            
        this.section_id = params.section_id;
      }
    )
   }

  ngOnInit() {
    this.paymentMenuItem = 'update-payment';
  }

  ngOnChanges() { 
    if (this.student_id != undefined) {
      this.viewStudents = true;
    }

    if (this.students.length == 0) {
      this.studentSelected = false;
      this.student_id = undefined;
    }
  }

  selectedSection(section_id: number) {
    this.section_id = section_id;
    if(section_id) {
      this.getStudentsBySectionId();
    }    
  }

  getStudentsBySectionId() {
    this.studentSelected = false;
    this.student_id = undefined;
    this.globalService.startLoader();
    if(this.section_id) {
      this.studentService.getStudentsBySection(this.section_id).subscribe(
        response => {
          this.students = response;  
          this.globalService.stopLoader();      
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], "Students");
        }
      )
    }
    
  }

  getStudent(student_id: number) {    
    this.student_id = student_id;
    this.viewNothing();
    this.viewPreviousPayments = true; 
    this.student_details = this.students.filter(student => student.id === this.student_id)[0];
    this.studentSelected = true;  
    this.fee_type_id = undefined; 
  }

  editPayment(paymentInfo: any) {    
    this.paymentInfo = paymentInfo;    
    this.fee_type_id = this.paymentInfo['fee_type_id'];
    this.viewNothing();
    this.studentSelected = true;
    this.viewPaymentForm = true;
  }

  payment_result(result: string) {
    if(result === 'success') {
      this.payment_saved = true;      
    }
  }

  selected_fee_type(fee_type_id: number) {
    this.fee_type_id = fee_type_id;
  }

  viewStudentsClick()
  {
    this.viewNothing();
    this.viewStudents = true;
  }

  viewPreviousPaymentsClick()
  {
    this.student_details = this.students.filter(student => student.id === this.student_id)[0];
    this.viewNothing();
    this.viewPreviousPayments = true;
  }

  viewNothing()
  {
    this.viewStudents = false;
    this.viewPreviousPayments = false;
    this.viewPaymentForm = false;
  }
}
