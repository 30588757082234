import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StudentService } from 'src/app/_services/operator/student.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatStepper } from '@angular/material/stepper';
import { HttpEventType } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-add-student',
  templateUrl: './add-student.component.html',
  styleUrls: ['./add-student.component.scss']
})
export class AddStudentComponent implements OnInit {

  section_id: number;  
  studentMenuItem: string;
  studentInfoFormGroup: FormGroup;
  studentFeeFormGroup: FormGroup;
  isOptional = true;
  student_id: number;
  studentImageUploadProgress: string = null;
  studentImageData: File = null;
  previewUrl: any = null;
  uploadedFilePath: string = null;

  constructor(private formBuilder: FormBuilder,
              private studentService: StudentService,              
              private globalService: GlobalService) { }

  ngOnInit() {
    this.studentMenuItem = 'add-student';
    this.studentInfoFormGroup = this.formBuilder.group({
      student_image: [],
      student_name: ['', Validators.required],
      father_name: ['', Validators.required],      
      mobile_number: ['']
    });

    this.studentFeeFormGroup = this.formBuilder.group({
      address: ['', Validators.required]
    });

    this.previewUrl = '../../../../assets/images/student-dummy.png';
  }

  selectedSection(selected_section) {
    this.section_id = selected_section;
  }

  saveStudent(stepper: MatStepper) {
    const studentData = {
      student_name: this.studentInfoForm('student_name'),
      father_name: this.studentInfoForm('father_name'),
      mobile_number: this.studentInfoForm('mobile_number'),
      section_id: this.section_id
    }

    this.globalService.startLoader();
    this.studentService.addStudent(studentData).subscribe(
      data => {        
        this.student_id = data['student_id'];
        this.uploadStudentImage();
        this.globalService.stopLoader();
        this.globalService.showMessage(data['message'], "Student");
        stepper.next();        
        this.studentInfoFormGroup.reset();
      },
      error => {                          
        this.globalService.showError(error['error']||error, "Student");
        this.globalService.stopLoader();  
        this.studentInfoFormGroup.invalid;
      }
    );
  }

  uploadStudentImage() {
    const formData = new FormData();
    formData.append('student_image', this.studentImageData);
    formData.append('student_id', this.student_id.toString());
    this.globalService.startLoader();
    this.studentService.uploadStudentImage(formData).subscribe(
      events => {
        if(events.type === HttpEventType.UploadProgress) {
          this.studentImageUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
          //console.log(this.studentImageUploadProgress);
        } else if(events.type === HttpEventType.Response) {
          this.studentImageUploadProgress = '';          
          //console.log(events.body);          
        }
        this.globalService.stopLoader();      
      },
      error => {
        this.globalService.showError(error['error'], 'Upload');
        this.globalService.stopLoader();      
      }
    )
  }

  studentInfoForm(form_field) {
    return this.studentInfoFormGroup.get(form_field).value;
  }

  onStudentImageInput(fileInput: any) {
    this.studentImageData = <File>fileInput.target.files[0]; 
    this.preview();
  }

  preview() {
    var mimeType = this.studentImageData.type;    
    if(mimeType.match(/image\/*/) == null) {
      this.previewUrl = '../../../../assets/images/student-dummy.png'
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.studentImageData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }    
  }

  isStudentDetailsCompleted(completed: boolean, stepper: MatStepper) {
    if(completed) {
      stepper.next();
    }
  }
}
