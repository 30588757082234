<app-select-course (selected_course_emit)="selectedCourse($event)"></app-select-course>

<div class="page-content-view-port p-3">
    
    <form [formGroup]="classTransferForm">        

        <mat-form-field style="margin-left: 2rem;" floatLabel="never">
            <mat-label style="color:red">From Class</mat-label>
            <mat-select formControlName="selected_from_class">
                <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="margin-left: 2rem;" floatLabel="never">
            <mat-label style="color:red">To Class</mat-label>
            <mat-select formControlName="selected_to_class">
                <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        
        <button mat-button color="custom-color" class="ml-5" (click)="promoteClass()">Promote</button>
        
    </form>
</div>