<ng-container *ngIf="globalService.isDesktop()">
    <div class="payment-container">
        <div class="students-list" [ngClass]="[studentSelected === true ? 'students-list-custom-width': 'students-list-full-width']">
            <h5 class="text-center payments-label"><strong>STUDENTS</strong></h5>
            <mat-list role="list">
                <mat-list-item *ngFor="let student of students" role="listitem" (click)="getStudent(student.id)" [ngClass]="{'student-active': student_id === student.id}">                
                    <h4 mat-line>
                        {{student.student_name}} 
                        <small>f/n {{student.father_name}}</small>
                    </h4>
                    <mat-divider [inset]="true"></mat-divider>                
                </mat-list-item>
            </mat-list>
        </div>
        <br/>
    
        <div [ngClass]="[studentSelected === true ? 'payment-history-custom-width': 'payment-history-full-width']" *ngIf="student_id">
            <h5 class="text-center payments-label"><strong>PREVIOUS PAYMENTS FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5>
            <app-previous-payments [student_id]="student_id" [fee_type_id]="fee_type_id"></app-previous-payments>
        </div>
        
        <div class="payment-form" *ngIf="studentSelected">
            <h5 class="text-center payments-label"><strong>PAY NOW</strong></h5><br />        
            <app-payment-form [student_id]="student_id" (selected_fee_type)="selected_fee_type($event)"></app-payment-form>
        </div>
    </div>
</ng-container>


<ng-container *ngIf="globalService.isMobile()||globalService.isTablet()">
    <div class="tab-buttons-div">
        <div class="p-2">
            <button mat-button class="tab-buttons" color="custom-color" [ngClass]="[viewStudents == true ? 'focus-button' : 'fade-button']" (click)="viewStudentsClick()">Select Student</button>
        </div>
        <div class="p-2" *ngIf="studentSelected">
            <button mat-button class="tab-buttons" color="custom-color" [ngClass]="[viewPaymentForm == true ? 'focus-button' : 'fade-button']" (click)="viewPaymentFormClick()">Payment Form</button>
        </div>
        <div class="p-2" *ngIf="studentSelected">
            <button mat-button class="tab-buttons" color="custom-color" [ngClass]="[viewPreviousPayments == true ? 'focus-button' : 'fade-button']" (click)="viewPreviousPaymentsClick()">Previous Payments</button>
        </div>
    </div>
    <div class="payment-container">
        <div class="students-list" *ngIf="viewStudents" class="students-list-full-width">
            <h5 class="text-center payments-label"><strong>STUDENTS</strong></h5>
            <mat-list role="list">
                <mat-list-item class="student-line-item" *ngFor="let student of students" role="listitem" (click)="getStudent(student.id)" [ngClass]="{'student-active': student_id === student.id}">                
                    <h4 mat-line>
                        {{student.student_name}} 
                        <small>f/n {{student.father_name}}</small>
                    </h4>
                    <mat-divider [inset]="true"></mat-divider>                
                </mat-list-item>
            </mat-list>
        </div>
        <br/>

        <div class="payment-history-full-width" *ngIf="student_id && viewPreviousPayments">
            <h5 class="text-center payments-label"><strong>PREVIOUS PAYMENTS FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5>
            <app-previous-payments [student_id]="student_id" [fee_type_id]="fee_type_id"></app-previous-payments>
        </div>

        <div class="payment-form mobile_payment-form" *ngIf="studentSelected && viewPaymentForm">
            <h5 class="text-center payments-label"><strong>PAYMENT FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5><br />        
            <app-payment-form [student_id]="student_id" (selected_fee_type)="selected_fee_type($event)"></app-payment-form>
        </div>
    </div>
</ng-container>