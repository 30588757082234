<app-payment-menu [paymentMenuItem]="paymentMenuItem"></app-payment-menu>

<div class="search-toolbar page-content-view-port">

    <mat-toolbar class="color-light p-1" style="height: auto;">
        <span>Search by </span>
        <form [formGroup]="searchPaymentForm">
            <mat-form-field class="ml-3">
                <input matInput placeholder="Receipt Number" formControlName="receipt_number">
            </mat-form-field>
            <button mat-icon-button class="default-background ml-1" (click)="searchPayments()">
                <mat-icon aria-label="label">search</mat-icon>
            </button>
        </form>
    </mat-toolbar>


</div>

<div class="search-payment-container page-content-view-port" *ngIf="search_payment_results">
    <div class="search-payment-results"  [ngClass]="[updatePayment === true ? 'search-payments-custom-width' : 'search-payments-full-width']">
        <mat-table [dataSource]="search_payment_results" *ngIf="search_payment_results.length > 0">
            <ng-container matColumnDef="s_no">
                <mat-header-cell *matHeaderCellDef> S.No
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results;let i=index"> {{i+1}} </mat-cell>               
            </ng-container>

            <ng-container matColumnDef="student_name">
                <mat-header-cell *matHeaderCellDef> Student Name
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results"> {{search_payment_results.student_name}} </mat-cell>               
            </ng-container>

            <ng-container matColumnDef="amount">
                <mat-header-cell *matHeaderCellDef> Amount
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results"> {{search_payment_results.paid_amount}} </mat-cell>               
            </ng-container>

            <ng-container matColumnDef="receipt_date">
                <mat-header-cell *matHeaderCellDef> Date
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results"> {{search_payment_results.receipt_date | date: 'dd-MM-yyyy'}} </mat-cell>               
            </ng-container>

            <ng-container matColumnDef="fee_type">
                <mat-header-cell *matHeaderCellDef> Fee Type
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results"> {{search_payment_results.fee_name}} </mat-cell>               
            </ng-container>

            <ng-container matColumnDef="action">
                <mat-header-cell *matHeaderCellDef> Action
                </mat-header-cell>
                <mat-cell *matCellDef="let search_payment_results">
                    <button mat-icon-button style="color:darkcyan">
                        <mat-icon aria-label="label" (click)="editPayment(search_payment_results.id, search_payment_results.student_id)">edit</mat-icon>
                    </button>
                    <button mat-icon-button style="color:maroon">
                        <mat-icon aria-label="label" (click)="removePayment(search_payment_results.id)">delete
                        </mat-icon>
                    </button>
                </mat-cell>              
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>            
        </mat-table>
    </div>

    <div class="search-update-payment-form" *ngIf="updatePayment">        
        <app-update-payment-form [student_id]="student_id" [paymentInfo]="paymentInfo"></app-update-payment-form>
    </div>
</div>