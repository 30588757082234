import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GlobalService } from 'src/app/_services/global.service';
import { EditStudentFeeDialogData } from '../previous-payments/previous-payments.component';

@Component({
  selector: 'app-edit-student-fee-dialog',
  templateUrl: './edit-student-fee-dialog.component.html',
  styleUrls: ['./edit-student-fee-dialog.component.scss']
})
export class EditStudentFeeDialogComponent implements OnInit {

  @Input() student_id: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public student_fee_dialog_data: EditStudentFeeDialogData,
    private globalService: GlobalService,
    private dialogRef: MatDialogRef<EditStudentFeeDialogComponent>    
  ) { }

  ngOnInit() {
    this.student_id = this.student_fee_dialog_data.student_id;
  }

  getResult(result: string) {
    if (result === 'success') {
      this.dialogRef.close();
      this.globalService.showMessage("Student Fee updated successfully", 'Student Fee');
    } else {
      this.globalService.showError(result, 'Student Fee');
    }
  }

}
