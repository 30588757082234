<app-select-section (section_id_emit)="selectedSection($event)"></app-select-section>

<div class="page-content-view-port mat-elevation-z8" *ngIf="section_id">
  <form [formGroup]="feeTypeSelectionForm">
    <mat-select class="report-fee-type-select" formControlName="fee_type_id" placeholder="Select Fee Type"
                    (selectionChange)="getFeeTypeIdSelect($event)">
        <mat-option value="total_fee" selected>Total Fee</mat-option>
        <mat-option *ngFor="let fee_type of fee_types" [value]="fee_type.id">
            {{fee_type.name}}</mat-option>
    </mat-select>
  </form>
  <table mat-table [dataSource]="payments_by_section">

    <!-- S.No. Column -->
    <ng-container matColumnDef="s_no">
      <th mat-header-cell *matHeaderCellDef> S.No. </th>
      <td mat-cell *matCellDef="let payments_by_section; let i = index;"> {{i+1}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Student Name Column -->
    <ng-container matColumnDef="student_name">
      <th mat-header-cell *matHeaderCellDef> Student Name </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.student_name}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Father Name Column -->
    <ng-container matColumnDef="father_name">
      <th mat-header-cell *matHeaderCellDef> Father Name </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.father_name}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Mobile Number Column -->
    <ng-container matColumnDef="mobile_number">
      <th mat-header-cell *matHeaderCellDef> Mobile Number </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.mobile_number}} </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Committed Fee Column -->
    <ng-container matColumnDef="committed_fee">
      <th mat-header-cell *matHeaderCellDef> Committed Fee </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.all_total_student_fee}} </td>
      <td mat-footer-cell *matFooterCellDef>
          <strong>{{ getTotalCommittedFee() }}</strong>
      </td>
    </ng-container>

    <!-- Paid Fee Column -->
    <ng-container matColumnDef="paid_fee">
      <th mat-header-cell *matHeaderCellDef> Paid Fee </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.all_total_paid_fee}} </td>
      <td mat-footer-cell *matFooterCellDef>
        <strong>{{ getTotalPaidFee() }}</strong>
      </td>
    </ng-container>

    <!-- Pending Fee Column -->
    <ng-container matColumnDef="pending_fee">
      <th mat-header-cell *matHeaderCellDef> Pending Fee </th>
      <td mat-cell *matCellDef="let payments_by_section"> {{payments_by_section.all_total_pending_fee}}</td>
      <td mat-footer-cell *matFooterCellDef>
        <strong>{{ getTotalPendingFee() }}</strong>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>

<div class="text-center mt-4 mb-4" *ngIf="section_id && feeTypeSelectionForm.valid">
  <button mat-button color="custom-color" (click)="print()">Print</button>
  <button class="ml-5" mat-button color="custom-color" (click)="sendSmsFeeReminder()">Send Fee Reminder SMS</button>
</div>
