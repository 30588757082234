<mat-toolbar class="menu-horizontal">
    <div class="d-flex align-center" style="display: inline-block;">
        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': createTransaction}" (click)="createTransactionClick()">            
            <span>CREATE TRANSACTION</span><br />
        </div>

        <div *ngIf="globalService.isUserAdmin() || globalService.isUserIncharge()" class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': viewTransactions}" (click)="viewTransactionsClick()">            
            <span *ngIf="globalService.isUserAdmin()">VIEW TRANSACTIONS</span>
            <span *ngIf="globalService.isUserIncharge()">TODAY</span>
        </div>
    </div>
</mat-toolbar>