<!--<div style="text-align: right;" *ngIf="students && students.length > 0">-->
<!--    <mat-select style="width:150px" #name placeholder="Change view" (selectionChange)="changeView($event.value)">-->
<!--        <mat-option value="listView">List View</mat-option>-->
<!--        <mat-option value="cardView">Cards View</mat-option>-->
<!--    </mat-select>-->
<!--</div>-->

<div class="students-list-container" *ngIf="students && students.length > 0">
    <div *ngFor="let student of students" class="d-flex" style="border-bottom:1px solid #4d4d4d">
        <div class="student-image" [ngStyle]="{'background-image': 'url(' + student.student_image + ')'}"></div>
        <div class="pt-0" style="overflow: auto;">
            <label><strong>{{student.student_name}}
            <small>s/o {{student.father_name}}</small></strong></label>
            <br/>
            <div class="p-0" style="display: inline-flex">
                <button mat-button color="custom-color" (click)="viewStudentDetails(student.id, 0)">Profile</button>
                <button *ngIf="isAdmin" class="ml-3" mat-button color="custom-color" (click)="viewStudentDetails(student.id, 1)">Student Fee</button>
                <button class="ml-3" mat-button color="custom-color" (click)="viewStudentDetails(student.id, 2)">Payments</button>
                <button class="ml-3" mat-button color="custom-color" (click)="viewStudentDetails(student.id, 3)">Attendance</button>
                <button class="ml-3" mat-button color="custom-color" (click)="viewStudentDetails(student.id, 4)">Marks</button>
                <button class="ml-3" mat-button color="custom-color" (click)="generateBonafide(student.id)">Bonafide</button>
                <button *ngIf="isAdmin" mat-icon-button class="ml-3" style="background: maroon;color:white" (click)="deleteStudent(student.id)">
                    <mat-icon aria-label="delete Student">delete</mat-icon>
                </button>
            </div>
        </div>
        <mat-divider style="margin:0;margin-top:10px" [inset]="true"></mat-divider>
    </div>
</div>

