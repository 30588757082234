import { Component, OnInit } from '@angular/core';
import { StudentService } from 'src/app/_services/operator/student.service';
import { StudentBasicDetails } from 'src/app/_models/student_basic_details';
import { isArray } from 'util';
import { AlumniService } from 'src/app/_services/operator/alumni.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-alumni-create',
  templateUrl: './alumni-create.component.html',
  styleUrls: ['./alumni-create.component.scss']
})
export class AlumniCreateComponent implements OnInit {

  alumniMenuItem: string;
  studentBasicDetails: StudentBasicDetails[];
  batch: any = [];
  section_id: number;
  displayedColumns = ['s_no', 'student_name', 'father_name', 'mobile_number', 'batch', 'action'];

  constructor(private studentService: StudentService,
              private alumniService: AlumniService,
                            
              private globalService: GlobalService) { }

  ngOnInit() {
    this.alumniMenuItem = 'add-alumni';
    this.globalService.page_title.next('Alumni');
  }

  getStudentsBySection(section_id: number) {
    if(section_id !== undefined) {
      this.section_id = section_id;
      this.studentService.getStudentsBasicInfoBySection(section_id).subscribe(
        response => {
          if(isArray(response)) {
            this.studentBasicDetails = response;
            for (let index = 0; index < this.studentBasicDetails.length; index++) {
              this.batch[index] = new Date().getFullYear();              
            }
          }          
        },
        error => {
          this.globalService.showError(error['error'], "Students");
        }
      )
    }    
  }

  addAsAlumni(index, studentInfo, batch) {
    const student = {
      student_id: studentInfo['id'],
      batch: batch
    }
    this.globalService.startLoader();
    this.alumniService.createAlumni(student).subscribe(
      response => {
        this.getStudentsBySection(this.section_id);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Alumni');
      },
      error => {
        this.globalService.showMessage(error['error'], 'Alumni');
        this.globalService.stopLoader();
      }
    )
    
  }

}
