import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StudentService } from 'src/app/_services/operator/student.service';
import { HttpEventType } from '@angular/common/http';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-edit-student',
  templateUrl: './edit-student.component.html',
  styleUrls: ['./edit-student.component.scss']
})
export class EditStudentComponent implements OnInit {

  @Input() student_id: number;
  @Input() studentInfo: any;
  editStudentInfoFormGroup: FormGroup;
  studentImageUploadProgress: string = null;
  studentImageData: File = null;
  previewUrl: any = null;
  uploadedFilePath: string = null;
  
  constructor(private fb: FormBuilder,
              private studentService: StudentService,
              private snackBar: MatSnackBar,
              
              private globalService: GlobalService) { 
    this.editStudentInfoFormGroup = this.fb.group({
      student_name : [],
      father_name: [],
      mobile_number: [],
      student_image: []
    });    
  }

  ngOnInit() {
    this.globalService.startLoader();
    this.studentService.getStudentBasicInfo(this.student_id).subscribe(
      response => {
        const studentInfo = response;
        this.editStudentInfoFormGroup.patchValue({
          student_name: studentInfo['student_name'],
          father_name: studentInfo['father_name'],
          mobile_number: studentInfo['mobile_number'],
        })

        this.previewUrl = studentInfo['student_image'];
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Basic Details');
      }
    )
  }

  onStudentImageInput(fileInput: any) {
    this.studentImageData = <File>fileInput.target.files[0]; 
    this.preview();
  }

  preview() {
    var mimeType = this.studentImageData.type;    
    if(mimeType.match(/image\/*/) == null) {
      this.previewUrl = '../../../../assets/images/student-dummy.png'
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.studentImageData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }    
  }

  
  updateStudentInfo() {

    const studentData = {
      student_name: this.studentInfoForm('student_name'),
      father_name: this.studentInfoForm('father_name'),
      mobile_number: this.studentInfoForm('mobile_number'),
      student_image: this.studentImageData
    }

    this.globalService.startLoader();
    this.studentService.editStudent(this.student_id, studentData).subscribe(
      response => {        
        if(this.studentImageData) {
          this.uploadStudentImage();
        }
        this.studentService.studentInfoUpdated.next(true);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Update');
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Update');
      }
    )
  }

  uploadStudentImage() {
    const formData = new FormData();
    formData.append('student_image', this.studentImageData);
    formData.append('student_id', this.student_id.toString());
    this.studentService.uploadStudentImage(formData).subscribe(
      events => {
        if(events.type === HttpEventType.UploadProgress) {
          this.studentImageUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
          //console.log(this.studentImageUploadProgress);
        } else if(events.type === HttpEventType.Response) {
          this.studentImageUploadProgress = '';          
          //console.log(events.body);          
        }
      },
      error => {
        this.globalService.showError(error['error'], 'Upload');
      }
    )
  }

  studentInfoForm(form_field) {
    return this.editStudentInfoFormGroup.get(form_field).value;
  }
}
