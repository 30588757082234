<mat-toolbar class="page-content-view-port">
    <!-- Form -->
    <form class="select-form" [formGroup]="selectClassForm">

        <mat-form-field style="margin-left: 2rem;" floatLabel="never">
            <mat-label style="color:red">Select Course</mat-label>
            <mat-select formControlName="selected_course" (selectionChange)="getSelectedCourse($event)">
                <mat-option *ngFor="let course of all_courses" [value]="course.id">{{course.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field style="margin-left: 2rem;" floatLabel="never">
            <mat-label style="color:red">Select Class</mat-label>
            <mat-select formControlName="selected_class" (selectionChange)="getSelectedClass($event)">
                <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
            </mat-select>
        </mat-form-field>
    </form>

</mat-toolbar>