<app-student-menu [studentMenuItem]="studentMenuItem"></app-student-menu>

<div class="search-toolbar page-content-view-port">

    <mat-toolbar class="color-light p-1 page-content-view-port">
        <span>Search by </span>
        <form [formGroup]="searchStudentForm">
            <mat-form-field class="ml-3" floatLabel="never">
                <input matInput placeholder="Name" formControlName="student_name">
            </mat-form-field>
            <button mat-icon-button class="default-background ml-3" (click)="searchStudent()">
                <mat-icon aria-label="label">search</mat-icon>
            </button>
        </form>
    </mat-toolbar>

</div>

<div class="search-student-container page-content-view-port" *ngIf="students && students.length > 0 && viewType === 'cardView'">
    <app-students-card-view [students]="students" style="width: 100%;" (refreshStudentsList)="refreshStudentsList($event)"></app-students-card-view>
</div>

<div class="search-student-container page-content-view-port" *ngIf="students && students.length > 0 && viewType === 'listView'">
    <app-students-list-view [students]="students" style="width: 100%;" (refreshStudentsList)="refreshStudentsList($event)"></app-students-list-view>
</div>

<div class="search-student-container page-content-view-port p-2" style="justify-content: center;" *ngIf="students && students.length === 0">
    <h3>No Student found</h3>
</div>