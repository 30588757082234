import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable({providedIn: 'root'})
export class ErrorInterceptor implements HttpInterceptor{
    
    constructor(private authenticationService: AuthenticationService){}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            if ([401].indexOf(err.status) != -1) {
                this.authenticationService.logout();
            }            
            //const error = err.error.message || err.statusText; 
            let error: string;
            if(err.status === 422) {
                error = "Inputs missing. Please check";
            } else {
                error = err.error || err.statusText;
            }
            
            return throwError(error);
        }))
    }
    
}