<mat-toolbar class="previous-payments-toolbar">
    <span><h1>{{fee_type}}</h1></span>
    <span style="flex:auto 1 1"></span>
    <span><h1>{{committed_fee}}</h1></span>
    <span *ngIf="isAdmin" ><button mat-icon-button (click)="openStudentFeeDialog()">
        <mat-icon aria-label="edit">edit</mat-icon>
    </button></span>
</mat-toolbar>
<mat-table [dataSource]="studentPreviousPayments" class="previous-payments">
    <ng-container matColumnDef="s_no">
        <mat-header-cell *matHeaderCellDef> S.No
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments;let i=index"> {{i+1}} </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="receipt_date">
        <mat-header-cell *matHeaderCellDef> Date
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.receipt_date}} </mat-cell>
        <mat-footer-cell *matFooterCellDef> <strong>Paid Total</strong>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="receipt_number">
        <mat-header-cell *matHeaderCellDef> Receipt No.
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.receipt_number}} </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> Amount
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.paid_amount}} </mat-cell>
        <mat-footer-cell *matFooterCellDef> <strong>{{paid_total}}</strong>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="paid_by">
        <mat-header-cell *matHeaderCellDef> Paid by
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.paid_by}} </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="collected_by">
        <mat-header-cell *matHeaderCellDef> Collected by
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.collected_by}} </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="fee_type">
        <mat-header-cell *matHeaderCellDef> Fee Type
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> {{studentPreviousPayments.name}} </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>

    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> Action
        </mat-header-cell>
        <mat-cell *matCellDef="let studentPreviousPayments"> 
            <button mat-icon-button style="color:darkcyan" *ngIf="editPaymentInfo.observers.length > 0">
                <mat-icon aria-label="label" (click)="editPayment(studentPreviousPayments.id)">edit</mat-icon>                
            </button>
            <button *ngIf="isAdmin" mat-icon-button style="color:maroon">
                <mat-icon aria-label="label" (click)="removePayment(studentPreviousPayments.id)">delete</mat-icon>                
            </button>
        </mat-cell>
        <mat-footer-cell *matFooterCellDef>
        </mat-footer-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    <mat-footer-row *matFooterRowDef="displayedColumns"></mat-footer-row>
</mat-table>
<br/><br/>

<mat-toolbar class="previous-payments-toolbar" style="padding-right: 16px;">
    <span ><h1>Pending Fee</h1></span>
    <span style="flex:auto 1 1"></span>
    <span ><h1>{{pending_fee}}</h1></span>
</mat-toolbar>

<div class="text-center mt-4 mb-4" *ngIf="student_id">
    <button mat-button color="custom-color" (click)="print()">Print</button>
</div>