import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewStudentsDialogData } from '../view-students/view-students.component';
import { StudentService } from 'src/app/_services/operator/student.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-view-student-dialog',
  templateUrl: './view-student-dialog.component.html',
  styleUrls: ['./view-student-dialog.component.scss']
})
export class ViewStudentDialogComponent implements OnInit {

  student_id: number;
  student_info: any;
  tab_index: number;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public dialog_data: ViewStudentsDialogData,
    private studentService: StudentService,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.student_id = this.dialog_data.student_id;
    this.tab_index = this.dialog_data.tab_index;
    this.globalService.startLoader();
    this.studentService.getStudentBasicInfo(this.student_id).subscribe(
      response => {
        this.student_info = response;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], "Basic Details")
      }
    )
  }

  isStudentDetailsCompleted(completed: boolean) {
    if(completed) {
      this.globalService.showMessage("Updated successfully", "Update");
    }
  }

}
