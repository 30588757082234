import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';
import { FeeService } from 'src/app/_services/operator/fee.service';

@Component({
  selector: 'app-student-payments',
  templateUrl: './student-payments.component.html',
  styleUrls: ['./student-payments.component.scss']
})
export class StudentPaymentsComponent implements OnInit {

  @Input() student_id: number;
  @Input() fee_type_id: number;
  student_fee_types: any;
  feeTypeSelectionForm: FormGroup;
  showPreviousPayments: boolean = true;
  showPaymentForm: boolean = false;

  constructor(
    private feeService: FeeService,
    private fb: FormBuilder,
    
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    
    this.feeTypeSelectionForm = this.fb.group({
      fee_type_id: ['']
    })

    this.globalService.startLoader();
    this.feeService.getFeeTypes(this.student_id).subscribe(
      response => {
        this.student_fee_types = response;  
        this.globalService.stopLoader();      
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Class Fee');
      }
    )
  }

  getFeeTypeIdSelect(fee_type_id) {
    this.fee_type_id = fee_type_id.value;
  }

  selected_fee_type(fee_type_id: number) {
    this.fee_type_id = fee_type_id;
  }

  payNowClick() {
    this.showPaymentForm = true;
    this.showPreviousPayments = false;
  }

  previousPaymentsClick() {
    this.showPaymentForm = false;
    this.showPreviousPayments = true;    
  }
}
