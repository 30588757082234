import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CourseService {
  
  private getCoursesURL = environment.API_URL + '/courses';
  
  constructor(private httpClient: HttpClient) { }

  getCourses() {
    return this.httpClient.get(this.getCoursesURL);
  }

  getCourseIdBySection(section_id: number) {
    return this.httpClient.get(this.getCoursesURL + '/section/' + section_id);
  }

  getAllCourses() {
    return this.httpClient.get(environment.API_URL + '/courses/all');
  }
}
