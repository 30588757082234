import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CourseService } from 'src/app/_services/operator/course.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ClassService } from 'src/app/_services/operator/class.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-promote-class',
  templateUrl: './promote-class.component.html',
  styleUrls: ['./promote-class.component.scss']
})
export class PromoteClassComponent implements OnInit {

  selectCourseForm: FormGroup;
  classTransferForm: FormGroup;
  all_courses: any;
  all_classes: any;
  course: number;

  constructor(
    private globalService: GlobalService,
    private fb: FormBuilder,
    private courseService: CourseService,
    private classService: ClassService,
    
    private snackbar: MatSnackBar    
  ) {
   }

  ngOnInit() {    

    this.globalService.page_title.next("Class Transfer");

    this.selectCourseForm = this.fb.group({
      selected_course : ['']
    });
    
    this.classTransferForm = this.fb.group({
      selected_from_class : [],
      selected_to_class: []
    })

  }

  selectedCourse(selected_course: number) {
    this.course = selected_course;
    this.globalService.startLoader();      
    this.classService.getClasses(this.course).subscribe(
      response => {
        this.all_classes = response;
        this.globalService.stopLoader();    
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Get Classes');
      }
    )
  }

  promoteClass() {
    const data = {
      from_class_id: this.classTransferForm.controls.selected_from_class.value,
      to_class_id : this.classTransferForm.controls.selected_to_class.value
    }
    this.globalService.startLoader();
    this.classService.promoteClass(data).subscribe(
      data => {        
        this.globalService.stopLoader();
        this.snackbar.open(data['message'], 'Status', {
          duration: 3000
        });
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Class Transfer');
      }
    )
  }

}
