import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { StudentJoiningDetails } from 'src/app/_models/student_joining_details';
import { StudentService } from 'src/app/_services/operator/student.service';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StudentPersonalDetails } from 'src/app/_models/student_personal_details';
import { StudentOtherDetails } from 'src/app/_models/student_other_details';
import { StudentRemovalDetails } from 'src/app/_models/student_removal_details';
import { CourseService } from 'src/app/_services/operator/course.service';
import { ClassService } from 'src/app/_services/operator/class.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';
import { GlobalService } from 'src/app/_services/global.service';


@Component({
  selector: 'app-student-details',
  templateUrl: './student-details.component.html',
  styleUrls: ['./student-details.component.scss']
})
export class StudentDetailsComponent implements OnInit {

  @Input() student_id: number;
  @Output() completed: EventEmitter<boolean> = new EventEmitter<boolean>(false);
  all_courses: any;
  all_classes: any;
  startForm: boolean = true;
  saveJoining: boolean = false;
  savePersonal: boolean = false;
  saveOther: boolean = false;
  saveRemoval: boolean = false;
  skipRemoval: boolean = false;
  studentJoiningDetailsFormGroup: FormGroup;
  studentPersonalDetailsFormGroup: FormGroup;
  studentOtherDetailsFormGroup: FormGroup;
  studentRemovalDetailsFormGroup: FormGroup;
  private studentJoiningData: StudentJoiningDetails = new StudentJoiningDetails();
  private studentPersonalData: StudentPersonalDetails = new StudentPersonalDetails();
  private studentOtherData: StudentOtherDetails = new StudentOtherDetails();
  private studentRemovalData: StudentRemovalDetails = new StudentRemovalDetails();

  constructor(private fb: FormBuilder,
    private studentService: StudentService,
    private snackBar: MatSnackBar,
    private courseService: CourseService,
    private classService: ClassService,
    
    private globalService: GlobalService) {

    this.studentJoiningDetailsFormGroup = this.fb.group({
      student_id: this.student_id,
      admission_number: [],
      date_of_admission: []
    });

    this.studentPersonalDetailsFormGroup = this.fb.group({
      student_id: this.student_id,
      mother_name: [],
      parent_occupation: [],
      parent_annual_income: [],
      student_email: [],
      gender: [],
      aadhaar_number: [],
      caste: [],
      mother_tongue: [],
      religion: [],
      nationality: [],
      date_of_birth: [],
      identification_marks: [],
      address: []
    });

    this.studentOtherDetailsFormGroup = this.fb.group({
      student_id: this.student_id,
      last_studied_institution: [],
      noc_issued: [],
      class_of_admission: [],
      selected_course: []
    });

    this.studentRemovalDetailsFormGroup = this.fb.group({
      student_id: this.student_id,
      class_of_removal: [],
      date_of_removal: [],
      tc_record_sheet_number: [],
      reasons_for_removal: [],
      remarks: [],
      selected_course: []
    })

  }

  ngOnInit() {
    this.disableAllForms();
    this.studentJoiningDetailsFormGroup.enable();
    if (!localStorage.getItem('courses')) {
      this.getCourses();
    } else {
      this.all_courses = JSON.parse(localStorage.getItem('courses'));
    }
    if(this.student_id) {
      this.studentService.getStudentDetails(this.student_id).subscribe(
        response => {
          const student_details = response;
          if(student_details) {
            this.studentJoiningDetailsFormGroup.patchValue({
              admission_number: student_details['admission_number'],
              date_of_admission: moment.utc(student_details['date_of_admission']).toDate()
            });
    
            this.studentPersonalDetailsFormGroup.patchValue({
              mother_name: student_details['mother_name'],
              parent_occupation: student_details['parent_occupation'],
              parent_annual_income: student_details['parent_annual_income'],
              student_email: student_details['email'],
              gender: student_details['gender'],
              aadhaar_number: student_details['aadhaar_number'],
              caste: student_details['caste'],
              mother_tongue: student_details['mother_tongue'],
              religion: student_details['religion'],
              nationality: student_details['nationality'],
              date_of_birth: moment.utc(student_details['date_of_birth']).toDate(),
              identification_marks: student_details['identificaiton_marks'],
              address: student_details['address'],
            });
    
            this.studentOtherDetailsFormGroup.patchValue({
              last_studied_institution: student_details['last_studied_institution'],
              noc_issued: student_details['noc_issued'],
              class_of_admission: student_details['class_of_admission']
            });
    
            this.studentRemovalDetailsFormGroup.patchValue({
              class_of_removal: student_details['class_of_removal'],
              date_of_removal: moment.utc(student_details['date_of_removal']).toDate(),
              tc_record_sheet_number: student_details['tc_record_sheet_number'],
              reasons_for_removal: student_details['reasons_for_removal'],
              remarks: student_details['remarks'],
            })
          }         
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], "Student Details");          
        }
      );
    }

  }

  getCourses() {    
    this.globalService.startLoader();
    this.courseService.getCourses().subscribe(
      response => {
        this.all_courses = response;
        localStorage.setItem('courses', JSON.stringify(response));
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Course');
      }
    );
  }

  getClasses(selected_course: FormControl) {
    if (this.all_courses) {
      this.all_classes = this.all_courses
                            .filter(course => course.id === selected_course.value)[0]
                            .classes;
    }
  }

  saveJoiningDetails() {
    this.saveJoining = true;
    this.startForm = false;
    this.studentJoiningData = <StudentJoiningDetails>this.studentJoiningDetailsFormGroup.value;
    this.studentJoiningData.student_id = this.student_id;
    this.globalService.startLoader();
    this.studentService.saveStudentJoiningDetails(this.studentJoiningData).subscribe(
      response => {
        this.disableAllForms();
        this.studentPersonalDetailsFormGroup.enable();
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Save Joining');
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Save Joining')
      }
    )
  }

  savePersonalDetails() {
    this.savePersonal = true;
    this.saveJoining = false;
    this.studentPersonalData = <StudentPersonalDetails>this.studentPersonalDetailsFormGroup.value;
    this.studentPersonalData.student_id = this.student_id;
    this.globalService.startLoader();
    this.studentService.saveStudentPersonalDetails(this.studentPersonalData).subscribe(
      response => {
        this.disableAllForms();
        this.studentOtherDetailsFormGroup.enable();
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Save Personal');
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Save Personal')
      }
    )
  }

  saveOtherDetails() {
    this.saveOther = true;
    this.savePersonal = false;
    this.studentOtherData = <StudentOtherDetails>this.studentOtherDetailsFormGroup.value;
    this.studentOtherData.student_id = this.student_id;
    this.globalService.startLoader();
    this.studentService.saveStudentOtherDetails(this.studentOtherData).subscribe(
      response => {
        this.disableAllForms();
        this.studentRemovalDetailsFormGroup.enable();
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Save Other');        
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Save Other')
      }
    )
  }

  saveRemovalDetails() {
    this.saveRemoval = true;
    this.saveOther = false;
    this.studentRemovalData = <StudentRemovalDetails>this.studentRemovalDetailsFormGroup.value;
    this.studentRemovalData.student_id = this.student_id;
    this.globalService.startLoader();
    this.studentService.saveStudentRemovalDetails(this.studentRemovalData).subscribe(
      response => {
        this.disableAllForms();
        this.completed.emit(true);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Save Removal');        
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Save Removal')
      }
    )
  }

  skipRemovalDetails() {
    this.disableAllForms();    
    this.completed.emit(true);  
    this.globalService.showMessage("Skipped Removal info", "Skip");
  }

  disableAllForms() {
    this.studentJoiningDetailsFormGroup.disable();
    this.studentPersonalDetailsFormGroup.disable();
    this.studentOtherDetailsFormGroup.disable();
    this.studentRemovalDetailsFormGroup.disable();
  }

}
