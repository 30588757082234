import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GlobalService } from 'src/app/_services/global.service';
import { SectionWiseFeeDetailsComponent } from '../section-wise-fee-details/section-wise-fee-details.component';
import { SectionWiseStudentsListComponent } from '../section-wise-students-list/section-wise-students-list.component';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  isAdmin: boolean;

  constructor(
    private globalService: GlobalService,
    private matDialog: MatDialog) {
    this.isAdmin = globalService.isUserAdmin();
  }

  ngOnInit() {
    this.globalService.page_title.next('Reports');
  }

  openSectionWiseStudentsListDialog()
  {
    this.matDialog.open(SectionWiseStudentsListComponent);
  }

}
