import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';
import { TransactionService } from 'src/app/_services/transaction.service';

@Component({
  selector: 'app-transaction-form',
  templateUrl: './transaction-form.component.html',
  styleUrls: ['./transaction-form.component.scss']
})
export class TransactionFormComponent implements OnInit {

  transactionMenuItem: string;

  constructor(
    private globalService: GlobalService) { }

  ngOnInit() {
    this.transactionMenuItem = 'create-transaction';
  }
}
