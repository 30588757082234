import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, AfterViewChecked {

  page_header: string;
  branch_header_text: string;
  isAdmin: boolean;
  isIncharge: boolean;
  isOperator: boolean;
  username: string;

  constructor(
    private globalService: GlobalService,
    private authService: AuthenticationService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.globalService.page_header.subscribe(
      data => {
        this.page_header = data;
      }
    );

    const user = this.authService.getUser();
    this.isAdmin = this.globalService.isUserAdmin();
    this.isIncharge = this.globalService.isUserIncharge();
    this.isOperator = this.globalService.isUserOperator();
    this.branch_header_text = user.branch_header_text;
    this.username = user.first_name + ' ' + user.last_name;
  }

  setTitle(title: string) {
    this.globalService.page_title.next(title);
    this.globalService.page_header.subscribe(
      data => {
        this.page_header = data;
      }
    );
  }

  ngAfterViewChecked () {
    this.cdRef.detectChanges();
  }

}
