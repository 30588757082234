<app-select-course (selected_course_emit)="selectedCourse($event)"></app-select-course>

<div class="page-content-view-port mat-elevation-z8" *ngIf="result">
    <form [formGroup]="feeTypeSelectionForm">
      <mat-select class="report-fee-type-select" formControlName="fee_type_id" placeholder="Select Fee Type"
                      (selectionChange)="selectedFeeType($event)">
          <mat-option value="total_fee" selected>Total Fee</mat-option>
          <mat-option *ngFor="let fee_type of fee_types" [value]="fee_type.id">
              {{fee_type.name}}</mat-option>
      </mat-select>
    </form>
    <table mat-table [dataSource]="payments_by_course">
  
      <!-- S.No. Column -->
      <ng-container matColumnDef="s_no">
        <th mat-header-cell *matHeaderCellDef> S.No. </th>
        <td mat-cell *matCellDef="let payments_by_course; let i = index;"> {{i+1}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>

      <!-- Section Name Column -->
      <ng-container matColumnDef="class_name">
        <th mat-header-cell *matHeaderCellDef> Class </th>
        <td mat-cell *matCellDef="let payments_by_course; let i = index;"> {{payments_by_course.name}} </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      
      <!-- Committed Fee Column -->
      <ng-container matColumnDef="committed_fee">
        <th mat-header-cell *matHeaderCellDef> Committed Fee </th>
        <td mat-cell *matCellDef="let payments_by_course"> {{payments_by_course.all_total_committed_fee}} </td>
        <td mat-footer-cell *matFooterCellDef>
            <strong>{{ getTotalCommittedFee() }}</strong>            
        </td>
      </ng-container>

      <!-- Paid Fee Column -->
      <ng-container matColumnDef="paid_fee">
        <th mat-header-cell *matHeaderCellDef> Paid Fee </th>
        <td mat-cell *matCellDef="let payments_by_course"> {{payments_by_course.all_total_paid_fee}} </td>
        <td mat-footer-cell *matFooterCellDef>
          <strong>{{ getTotalPaidFee() }}</strong>            
        </td>
      </ng-container>

      <!-- Pending Fee Column -->
      <ng-container matColumnDef="pending_fee">
        <th mat-header-cell *matHeaderCellDef> Pending Fee </th>
        <td mat-cell *matCellDef="let payments_by_course"> {{payments_by_course.all_total_pending_fee}}</td>
        <td mat-footer-cell *matFooterCellDef>
          <strong>{{ getTotalPendingFee() }}</strong>            
        </td>
      </ng-container>
      
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
    </table>
</div>

<div class="text-center mt-4 mb-4" *ngIf="selected_course && feeTypeSelectionForm.valid">
  <button mat-button color="custom-color" (click)="print()">Print</button>
</div>