import { Component, OnInit, Input, OnChanges, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PaymentService } from 'src/app/_services/operator/payment.service';
import { StudentPayment } from 'src/app/_models/student_payment';
import { GlobalService } from 'src/app/_services/global.service';
import { FeeService } from 'src/app/_services/operator/fee.service';

@Component({
  selector: 'app-update-payment-form',
  templateUrl: './update-payment-form.component.html',
  styleUrls: ['./update-payment-form.component.scss']
})
export class UpdatePaymentFormComponent implements OnInit, OnChanges {

  @Input() student_id: number;
  @Input() paymentInfo: any;
  @Output() selected_fee_type = new EventEmitter<string>();
  updatePaymentForm: FormGroup;
  student_fee_types: any;
  set_fee_type_id: number;
  updateStudentPayment: StudentPayment;

  constructor(private fb: FormBuilder,
    private feeService: FeeService,
    private paymentService: PaymentService,
    private globalService: GlobalService) {
    this.updatePaymentForm = this.fb.group({
      payment_id: [],
      fee_type_id: [''],
      receipt_date: [],
      receipt_number: [],
      amount: [],
      paid_by: [],
      collected_by: []
    })    
    
  }

  ngOnInit() {
    this.globalService.startLoader();
    this.feeService.getFeeTypes(this.student_id).subscribe(
      response => {
        this.student_fee_types = response;  
        this.globalService.stopLoader();      
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Class Fee');
      }
    )
  }

  ngOnChanges() {    
    if(this.paymentInfo.fee_type_id == null ){
      this.paymentInfo.fee_type_id = '';
    } 
    
    this.updatePaymentForm.patchValue({
      'payment_id': this.paymentInfo.id,
      'receipt_number': this.paymentInfo.receipt_number,
      'fee_type_id': this.paymentInfo.fee_type_id,
      'receipt_date': new Date(this.paymentInfo.iso_receipt_date),
      'amount': this.paymentInfo.paid_amount,
      'paid_by': this.paymentInfo.paid_by,
      'collected_by': this.paymentInfo.collected_by
    });
  
  }

  savePayment() {
    const updateStudentPayment = {      
      student_id: this.student_id,
      fee_type_id: this.updateFeePaymentForm('fee_type_id'),
      receipt_date: this.updateFeePaymentForm('receipt_date'),
      receipt_number: this.updateFeePaymentForm('receipt_number'),
      amount: this.updateFeePaymentForm('amount'),
      paid_by: this.updateFeePaymentForm('paid_by'),
      collected_by: this.updateFeePaymentForm('collected_by'),
      payments: ''
    }
    
    this.globalService.startLoader();
    this.paymentService.updatePayments(this.updateFeePaymentForm('payment_id'), updateStudentPayment).subscribe(
      response => {
        this.paymentService.payment_saved.next(true);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Payment');
        this.updatePaymentForm.reset();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showMessage(error['error'], 'Payment');
      }
    )
  }

  updateFeePaymentForm(form_field) {
    return this.updatePaymentForm.get(form_field).value;
  }

  getFeeTypeIdSelect(fee_type_id) {
    if(this.updateFeePaymentForm('fee_type_id') === '') {
      this.updatePaymentForm.disable();
      this.updatePaymentForm.get('fee_type_id').enable();    
    }  else {
      this.updatePaymentForm.enable();
    }
    
    this.paymentService.pending_balance.subscribe(
      pending_balance => {
        if(this.updateFeePaymentForm('fee_type_id') === '') {
          this.updatePaymentForm.disable();
          this.updatePaymentForm.get('fee_type_id').enable();    
        }  else {
          this.updatePaymentForm.enable();
        }        
        if(pending_balance === 0) {
          this.updatePaymentForm.disable();
          this.updatePaymentForm.get('fee_type_id').enable();              
        } 
      }
    );

    this.selected_fee_type.emit(fee_type_id.value);
  }

}
