<div class="student-details-form-container">
    <form [formGroup]="studentJoiningDetailsFormGroup">
        <div class="student-details-form joining-details" [ngClass]="{'activate-form' : startForm}">
            <h3 class="mt-5">Joining Details</h3>
            <mat-form-field class="mt-2" >
                <input matInput placeholder="Admission Number" formControlName="admission_number">
            </mat-form-field>

            <mat-form-field class="date-field">
                <input matInput [matDatepicker]="doa_picker" formControlName="date_of_admission"  placeholder="Date of Admission" name="date_of_admission" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="doa_picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #doa_picker></mat-datepicker>
            </mat-form-field>

            <div class="d-flex flex-row-reverse" *ngIf="startForm">
                <button mat-button color="custom-color" (click)="saveJoiningDetails()">Save</button>
            </div>         
        </div>
    </form>

    <form [formGroup]="studentPersonalDetailsFormGroup">
        <div class="student-details-form personal-details" [ngClass]="{'activate-form' : saveJoining}">
            <h3 class="mt-5">Personal Details</h3>
            <mat-form-field class="mt-2">
                <input matInput placeholder="Mother Name" formControlName="mother_name">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Parent Occupation" formControlName="parent_occupation">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Annual Income" formControlName="parent_annual_income">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Student Email" formControlName="student_email">
            </mat-form-field>

            <mat-form-field>
                <mat-label>Gender</mat-label>
                <mat-select formControlName="gender">
                    <mat-option>Select</mat-option>
                    <mat-option value="M">Male</mat-option>
                    <mat-option value="F">Female</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Aadhaar Number" formControlName="aadhaar_number">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Caste (specify with Sub-caste)" formControlName="caste">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Mother Tongue" formControlName="mother_tongue">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Nationality" formControlName="nationality">
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Religion" formControlName="religion">
            </mat-form-field>

            <mat-form-field class="date-field">
                <input matInput [matDatepicker]="dob_picker" placeholder="Date of Birth (as per records)" formControlName="date_of_birth">
                <mat-datepicker-toggle matSuffix [for]="dob_picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dob_picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput placeholder="Identification Marks" formControlName="identification_marks"></textarea>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput placeholder="Full Address of the Student" formControlName="address"></textarea>
            </mat-form-field>

            <div class="d-flex flex-row-reverse" *ngIf="saveJoining">
                <button mat-button color="custom-color" (click)="savePersonalDetails()">Save</button>
            </div>
        </div>
    </form>
    <form [formGroup]="studentOtherDetailsFormGroup">
        <div class="student-details-form other_details" [ngClass]="{'activate-form' : savePersonal}">
            <h3 class="mt-5">Other Details</h3>

            <mat-form-field class="mt-2">
                <input matInput placeholder="Last studied Institution Name" formControlName="last_studied_institution">
            </mat-form-field>

            <mat-form-field>
                <mat-label>NOC Issued</mat-label>
                <mat-select formControlName="noc_issued">
                    <mat-option>Select</mat-option>
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field class="mt-2">
                <mat-label>Course of Admission</mat-label>
                <mat-select formControlName="selected_course" (selectionChange)="getClasses($event)">
                    <mat-option *ngFor="let course of all_courses" [value]="course.id">{{course.name}}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field style="margin-left: 2rem;">
                <mat-label>Class of Admission</mat-label>
                <mat-select formControlName="class_of_admission">
                    <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="d-flex flex-row-reverse" *ngIf="savePersonal">
                <button mat-button color="custom-color" (click)="saveOtherDetails()">Save</button>
            </div>
        </div>
    </form>

    <form [formGroup]="studentRemovalDetailsFormGroup">
        <div class="student-details-form removal_details" [ngClass]="{'activate-form' : saveOther}">
            <h3 class="mt-5">Removal Details</h3>

            <mat-form-field class="mt-2">
                <mat-label>Course of Removal</mat-label>
                <mat-select formControlName="selected_course" (selectionChange)="getClasses($event)">
                    <mat-option *ngFor="let course of all_courses" [value]="course.id">{{course.name}}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field style="margin-left: 2rem;">
                <mat-label>Class of Removal</mat-label>
                <mat-select formControlName="class_of_removal">
                    <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="date-field">
                <input matInput [matDatepicker]="dor_picker" placeholder="Date of Removal" formControlName="date_of_removal">
                <mat-datepicker-toggle matSuffix [for]="dor_picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #dor_picker></mat-datepicker>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="T.C/Record Sheet Number" formControlName="tc_record_sheet_number">
            </mat-form-field>

            <mat-form-field>
                <textarea matInput placeholder="Reasons for removal" formControlName="reasons_for_removal"></textarea>
            </mat-form-field>

            <mat-form-field>
                <textarea matInput placeholder="Remarks (if any)" formControlName="remarks"></textarea>
            </mat-form-field>

            <div class="d-flex flex-row-reverse" *ngIf="saveOther">
                <button mat-button color="custom-color" (click)="skipRemovalDetails()" class="ml-3">Skip</button>
                <button mat-button color="custom-color" (click)="saveRemovalDetails()">Save</button>
            </div>
        </div>
    </form>
</div>