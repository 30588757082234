import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthGuard } from './auth.guard';

@Injectable({providedIn: 'root'})
export class JwtInterceptor implements HttpInterceptor {
    
    constructor(private authenticationService: AuthenticationService,
                private authGuard: AuthGuard) {}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        const isApiUrl = request.url.startsWith(environment.API_URL);
        if(isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.authenticationService.getToken()}`
                }
            })
        }

        return next.handle(request).pipe(catchError(err => {
            if(err.status == 401) {
                this.authGuard.sessionExpired();
            }

            const error = err.error.message || err.statusText;
            return throwError(err);
        }));
    }


}