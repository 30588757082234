<app-alumni-menu [alumniMenuItem]="alumniMenuItem" ></app-alumni-menu>

<div class="page-content-view-port">
    <mat-toolbar class="page-content-view-port">
        <!-- Form -->
        <form class="select-form" [formGroup]="alumniViewForm">

            <mat-form-field style="margin-left: 2rem;" floatLabel="never">
                <mat-label style="color:red">Select Batch</mat-label>
                <mat-select formControlName="selected_batch" (selectionChange)="getCourses($event)">
                    <mat-option *ngFor="let batch of all_batches" [value]="batch">{{batch}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 2rem;" floatLabel="never">
                <mat-label style="color:red">Select Course</mat-label>
                <mat-select formControlName="selected_course" (selectionChange)="getClasses($event)">
                    <mat-option *ngFor="let course of all_courses" [value]="course.course_id">{{course.course_name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field style="margin-left: 2rem;" floatLabel="never">
                <mat-label style="color:red">Select Class</mat-label>
                <mat-select formControlName="selected_class" (selectionChange)="getAlumni($event)">
                    <mat-option *ngFor="let class of all_classes" [value]="class.class_id">{{class.class_name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </form>
        <!-- Form -->
    </mat-toolbar>
</div>


<div class="page-content-view-port mat-elevation-z8" *ngIf="studentInfo">
    <table mat-table [dataSource]="studentInfo">

        <!-- S.No Column -->
        <ng-container matColumnDef="s_no" sticky>
            <th mat-header-cell *matHeaderCellDef> S.No </th>
            <td mat-cell *matCellDef="let studentInfo; let i=index"> {{i+1}} </td>
        </ng-container>

        <!-- Admission Number Column -->
        <ng-container matColumnDef="admission_number" sticky>
            <th mat-header-cell *matHeaderCellDef> Admission Number </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.admission_number}} </td>
        </ng-container>

        <!-- Student Name Column -->
        <ng-container matColumnDef="student_name">
            <th mat-header-cell *matHeaderCellDef> Student Name </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_name}} </td>
        </ng-container>

        <!-- Father Name Column -->
        <ng-container matColumnDef="father_name">
            <th mat-header-cell *matHeaderCellDef> Father Name </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.father_name}} </td>
        </ng-container>

        <!-- Mother Name Column -->
        <ng-container matColumnDef="mother_name">
            <th mat-header-cell *matHeaderCellDef> Mother Name </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.mother_name}} </td>
        </ng-container>

        <!-- Date of Admission Column -->
        <ng-container matColumnDef="date_of_admission">
            <th mat-header-cell *matHeaderCellDef> Date of Admission </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.date_of_admission}} </td>
        </ng-container>

        <!-- Parent Occupation Column -->
        <ng-container matColumnDef="parent_occupation">
            <th mat-header-cell *matHeaderCellDef> Parent Occupation </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.parent_occupation}} </td>
        </ng-container>

        <!-- Parent Annual Income Column -->
        <ng-container matColumnDef="parent_annual_income">
            <th mat-header-cell *matHeaderCellDef> Parent Annual Income </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.parent_annual_income}} </td>
        </ng-container>

        <!-- Aadhaar Number Column -->
        <ng-container matColumnDef="aadhaar_number">
            <th mat-header-cell *matHeaderCellDef> Aadhaar Number </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.aadhaar_number}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef> Email </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.email}} </td>
        </ng-container>

        <!-- Gender Column -->
        <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef> Gender </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.gender}} </td>
        </ng-container>

        <!-- Caste Column -->
        <ng-container matColumnDef="caste">
            <th mat-header-cell *matHeaderCellDef> Caste </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.caste}} </td>
        </ng-container>

        <!-- Mother Tongue Column -->
        <ng-container matColumnDef="mother_tongue">
            <th mat-header-cell *matHeaderCellDef> Mother Tongue </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.mother_tongue}} </td>
        </ng-container>

        <!-- Nationality Column -->
        <ng-container matColumnDef="nationality">
            <th mat-header-cell *matHeaderCellDef> Nationality </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.nationality}} </td>
        </ng-container>

        <!-- Religion Column -->
        <ng-container matColumnDef="religion">
            <th mat-header-cell *matHeaderCellDef> Religion </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.religion}} </td>
        </ng-container>

        <!-- Date of Birth Column -->
        <ng-container matColumnDef="date_of_birth">
            <th mat-header-cell *matHeaderCellDef> Date of Birth </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.date_of_birth}} </td>
        </ng-container>

        <!-- Identification Marks Column -->
        <ng-container matColumnDef="identification_marks">
            <th mat-header-cell *matHeaderCellDef> Identification Marks </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.identification_marks}} </td>
        </ng-container>

        <!-- Address Column -->
        <ng-container matColumnDef="address">
            <th mat-header-cell *matHeaderCellDef> Address </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.address}} </td>
        </ng-container>

        <!-- Last Studied Institution Column -->
        <ng-container matColumnDef="last_studied_institution">
            <th mat-header-cell *matHeaderCellDef> Last Studied Institution </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.last_studied_institution}} </td>
        </ng-container>

        <!-- NOC Issued Column -->
        <ng-container matColumnDef="noc_issued">
            <th mat-header-cell *matHeaderCellDef> NOC Issued </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.noc_issued}} </td>
        </ng-container>

        <!-- Class of Admission Column -->
        <ng-container matColumnDef="class_of_admission">
            <th mat-header-cell *matHeaderCellDef> Class of Admission </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.class_of_admission}} </td>
        </ng-container>

        <!-- Class of Removal Column -->
        <ng-container matColumnDef="class_of_removal">
            <th mat-header-cell *matHeaderCellDef> Class of Removal </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.class_of_removal}} </td>
        </ng-container>

        <!-- Date of Removal Column -->
        <ng-container matColumnDef="date_of_removal">
            <th mat-header-cell *matHeaderCellDef> Date of Removal </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.date_of_removal}} </td>
        </ng-container>

        <!-- T.C Record Sheet Number Column -->
        <ng-container matColumnDef="tc_record_sheet_number">
            <th mat-header-cell *matHeaderCellDef> T.C Record Sheet Number </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.tc_record_sheet_number}} </td>
        </ng-container>

        <!-- Reasons for removal Column -->
        <ng-container matColumnDef="reasons_for_removal">
            <th mat-header-cell *matHeaderCellDef> Reasons for removal </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.reasons_for_removal}} </td>
        </ng-container>

        <!-- Remakrs Column -->
        <ng-container matColumnDef="remarks">
            <th mat-header-cell *matHeaderCellDef> Remarks </th>
            <td mat-cell *matCellDef="let studentInfo"> {{studentInfo.student_details.remarks}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let studentInfo; columns: displayedColumns;"></tr>
        
    </table>
</div>

