import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Transaction } from '../_models/transaction';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  private getTransactionsURL = environment.API_URL + '/transactions';
  
  constructor(private httpClient: HttpClient) { }

  createTransaction(transaction_data: any) {
    return this.httpClient.post(this.getTransactionsURL, transaction_data);
  }

  filterTransactions(data: any) {
    return this.httpClient.post(this.getTransactionsURL + '/filter', data);
  }

  getTransaction(id: number) {
    return this.httpClient.get<Transaction>(this.getTransactionsURL + '/' + id);
  }

  updateTransaction(id: number, data: FormData) {
    return this.httpClient.post(this.getTransactionsURL + '/' + id, data);
  }

  deleteTransaction(id: number) {
    return this.httpClient.delete(this.getTransactionsURL + '/' + id);
  }

  getTags() {
    return this.httpClient.get<string[]>(this.getTransactionsURL + '/tags');
  }
}
