<app-payment-menu [paymentMenuItem]="paymentMenuItem"></app-payment-menu>

<app-select-section (section_id_emit)="selectedSection($event)"></app-select-section>

<ng-container *ngIf="globalService.isDesktop()">
    <div class="page-content-view-port payment-container" *ngIf="section_id && students">
        <div class="students-list" [ngClass]="[studentSelected === true ? 'students-list-custom-width': 'students-list-full-width']">
            <h5 class="text-center payments-label"><strong>STUDENTS</strong></h5>
            <mat-list role="list">
                <mat-list-item *ngFor="let student of students" role="listitem" (click)="getStudent(student.id)" [ngClass]="{'student-active': student_id === student.id}">
                    <h4 mat-line>
                        <!-- <img mat-card-image src="../../../../assets/images/student-dummy.png" alt="Dummy student image" height="35" width="40">  -->
                        {{student.student_name}} 
                        <small>s/o {{student.father_name}}</small>
                    </h4>
                    <mat-divider [inset]="true"></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>

        <div [ngClass]="[studentSelected === true ? 'payment-history-custom-width': 'payment-history-full-width']" *ngIf="student_id">
            <h5 class="text-center payments-label"><strong>PREVIOUS PAYMENTS FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5>
            <app-previous-payments [student_id]="student_id" [fee_type_id]="fee_type_id" (editPaymentInfo)="editPayment($event)"></app-previous-payments>
        </div>

        <div class="payment-form" *ngIf="studentSelected && paymentInfo">
            <h5 class="text-center payments-label"><strong>UPDATE PAYMENT</strong></h5><br />        
            <app-update-payment-form [student_id]="student_id" (selected_fee_type)="selected_fee_type($event)" [paymentInfo]="paymentInfo"></app-update-payment-form>
        </div>    
    </div>
</ng-container>

<ng-container *ngIf="globalService.isMobile()">
    <div style="display:flex;overflow:auto" class="page-content-view-port tab-buttons-div">
        <div style="padding: 8px 0px;">
            <button mat-button class="tab-buttons" color="custom-color" [ngClass]="[viewStudents == true ? 'focus-button' : 'fade-button']" (click)="viewStudentsClick()">Select Student</button>
        </div>
        <div class="p-2" *ngIf="studentSelected && !viewStudents">
            <button mat-button class="tab-buttons" color="custom-color" [ngClass]="[viewPreviousPayments == true ? 'focus-button' : 'fade-button']" (click)="viewPreviousPaymentsClick()">Previous Payments</button>
        </div>
    </div>
    <div class="page-content-view-port payment-container" *ngIf="section_id && students">
        <div class="students-list" *ngIf="viewStudents" class="students-list-full-width">
            <h5 class="text-center payments-label"><strong>STUDENTS</strong></h5>
            <mat-list role="list">
                <mat-list-item class="student-line-item" *ngFor="let student of students" role="listitem" (click)="getStudent(student.id)" [ngClass]="{'student-active': student_id === student.id}">
                    <h4 mat-line>
                        <!-- <img mat-card-image src="../../../../assets/images/student-dummy.png" alt="Dummy student image" height="35" width="40">  -->
                        {{student.student_name}} 
                        <small>s/o {{student.father_name}}</small>
                    </h4>
                    <mat-divider [inset]="true"></mat-divider>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="payment-history-full-width" *ngIf="studentSelected && student_id && viewPreviousPayments">
            <h5 class="text-center payments-label"><strong>PREVIOUS PAYMENTS FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5>
            <app-previous-payments [student_id]="student_id" [fee_type_id]="fee_type_id" (editPaymentInfo)="editPayment($event)"></app-previous-payments>
        </div>

        <div class="payment-form mobile-payment-form" *ngIf="studentSelected && paymentInfo && viewPaymentForm">
            <h5 class="text-center payments-label"><strong>UPDATE PAYMENT FOR {{ student_details.student_name }} (F/N - {{ student_details.father_name }})</strong></h5><br />        
            <app-update-payment-form [student_id]="student_id" (selected_fee_type)="selected_fee_type($event)" [paymentInfo]="paymentInfo"></app-update-payment-form>
        </div> 
    </div>

</ng-container>