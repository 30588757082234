import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StudentPayment } from 'src/app/_models/student_payment';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { PreviousPayments } from 'src/app/_models/previous_payments';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  private getPaymentURL = environment.API_URL + '/payments';
  public payment_saved: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public fee_type_pending_balance: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  payment_result = this.payment_saved.asObservable();
  pending_balance = this.fee_type_pending_balance.asObservable();

  constructor(private httpClient: HttpClient) { }

  savePayment(data: StudentPayment) {
    return this.httpClient.post(this.getPaymentURL, data);
  }

  getPayments(student_id: Number) {
    return this.httpClient.get(this.getPaymentURL+'/student/'+student_id);
  }

  previousPayments(student_id: Number, fee_type_id: number): Observable<PreviousPayments> {
    if(!fee_type_id || fee_type_id === undefined) {
      return this.httpClient.get<PreviousPayments>(this.getPaymentURL + '/previous/'+student_id);  
    }
    return this.httpClient.get<PreviousPayments>(this.getPaymentURL + '/previous/'+student_id+'/'+fee_type_id);
  }

  updatePayments(payment_id: Number, data: StudentPayment) {
    return this.httpClient.put(this.getPaymentURL + '/' + payment_id, data);
  }

  deletePayment(payment_id: Number) {
    return this.httpClient.delete(this.getPaymentURL + '/' + payment_id);
  }

  getPaymentsByReceiptNumber(receipt_number: Number) {
    return this.httpClient.get(this.getPaymentURL+'/receipt/'+receipt_number);
  }

  
}
