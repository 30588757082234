import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { StudentService } from 'src/app/_services/operator/student.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-track-payments',
  templateUrl: './track-payments.component.html',
  styleUrls: ['./track-payments.component.scss']
})
export class TrackPaymentsComponent implements OnInit {

  section_id: number;
  students: any;
  paymentMenuItem: string;

  constructor(
    
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService
  ) {
    this.activatedRoute.params.subscribe(
      params => {                            
        this.section_id = params.section_id;
      }
    )
   }

  ngOnInit() {
    this.paymentMenuItem = 'track-payments';
  }

  selectedSection(section_id: number) {
    this.section_id = section_id;
    this.students = [];
    if(section_id) {
      this.getStudentsBySectionId();
    }
    
  }

  getStudentsBySectionId() {
    this.globalService.startLoader();
    this.studentService.getStudentsBySection(this.section_id).subscribe(
      response => {
        this.students = response;               
        this.globalService.stopLoader();
      },
      error => {                     
        this.globalService.stopLoader(); 
        this.globalService.showError(error['error'], "Students");
      }
    )
  }

}
