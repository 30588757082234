import {Component, OnInit, Output} from '@angular/core';
import {FormGroup, FormBuilder} from '@angular/forms';
import {EventEmitter} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {GlobalService} from 'src/app/_services/global.service';
import {AuthenticationService} from 'src/app/_services/authentication.service';
import {CourseService} from 'src/app/_services/operator/course.service';

@Component({
  selector: 'app-select-section',
  templateUrl: './select-section.component.html',
  styleUrls: ['./select-section.component.scss']
})
export class SelectSectionComponent implements OnInit {

  @Output() section_id_emit = new EventEmitter<string>();
  selected_course_id;
  selected_class_id;
  selected_section_id;

  selectSectionForm: FormGroup;
  all_courses: any;
  all_classes: any;
  all_sections: any;
  entityMenuItem: string;
  entity: string;


  constructor(private formBuilder: FormBuilder,
              private authService: AuthenticationService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private location: Location,
              private courseService: CourseService,
              private globalService: GlobalService) {
    this.activatedRoute.params.subscribe(
      params => {
        this.selected_course_id = params.course_id;
        this.selected_class_id = params.class_id;
        this.selected_section_id = params.section_id;
      }
    )
  }

  ngOnInit() {

    if (this.router.url.indexOf('students') !== -1) {
      this.entity = 'students';
    } else if (this.router.url.indexOf('payments') !== -1) {
      this.entity = 'payments';
    } else if (this.router.url.indexOf('reports') !== -1) {
      this.entity = 'reports';
    } else if (this.router.url.indexOf('alumni') !== -1) {
      this.entity = 'alumni';
    } else if (this.router.url.indexOf('attendance') !== -1) {
      this.entity = 'attendance';
    } else if (this.router.url.indexOf('sms') !== -1) {
      this.entity = 'sms';
    }


    if (this.router.url.indexOf('view') !== -1) {
      this.entityMenuItem = 'view';
    } else if (this.router.url.indexOf('create') !== -1) {
      this.entityMenuItem = 'create';
    } else if (this.router.url.indexOf('track') !== -1) {
      this.entityMenuItem = 'track';
    } else if (this.router.url.indexOf('update') !== -1) {
      this.entityMenuItem = 'update';
    } else if (this.router.url.indexOf('add') !== -1) {
      this.entityMenuItem = 'add';
    } else if (this.router.url.indexOf('section-wise-fee-details') !== -1) {
      this.entityMenuItem = 'section-wise-fee-details';
    } else if (this.router.url.indexOf('section-wise-fee-reminder') !== -1) {
      this.entityMenuItem = 'section-wise-fee-reminder';
    }

    this.selectSectionForm = this.formBuilder.group({
      selected_course: [''],
      selected_class: [''],
      selected_section: []
    });

    if (!localStorage.getItem('courses')) {
      this.getCourses();
    } else {
      this.all_courses = JSON.parse(localStorage.getItem('courses'));
      this.selectSchoolDefault();
    }

    this.navigateToActivatedRoute();

  }

  getCourses() {
    this.globalService.startLoader();
    this.courseService.getAllCourses().subscribe(
      response => {
        this.all_courses = response;
        localStorage.setItem('courses', JSON.stringify(response));
        this.selectSchoolDefault();
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.showError(error['error'], 'Courses');
        this.globalService.stopLoader();
      }
    );
  }

  selectSchoolDefault() {
    if (this.authService.organisationType() === 'school') {
      let selected_course = this.all_courses.filter(course => course.name.toLowerCase() === 'school')[0];
      if (selected_course) {
        this.selected_course_id = selected_course.id;
        this.navigateToActivatedRoute();
      }
    }
  }

  navigateToActivatedRoute() {

    if (this.selected_course_id) {
      this.selectSectionForm.get('selected_course').setValue(parseInt(this.selected_course_id));
      this.getClasses(this.selectSectionForm.controls.selected_course);
    }

    if (this.selected_class_id) {
      this.selectSectionForm.get('selected_class').setValue(parseInt(this.selected_class_id));
      this.getSections(this.selectSectionForm.controls.selected_class);

    }

    if (this.selected_section_id) {
      this.selectSectionForm.get('selected_section').setValue(parseInt(this.selected_section_id));
      this.sectionChange(this.selectSectionForm.controls.selected_section);
    }
  }

  getClasses(selected_course) {
    this.selectSectionForm.patchValue({
      selected_class: undefined,
      selected_section: undefined
    })
    this.navigateUrl();
    if (this.all_courses) {
      this.all_classes = this.all_courses
        .filter(course => course.id === selected_course.value)[0]
        .classes;
    }

  }

  getSections(selected_class) {
    this.section_id_emit.emit(undefined);
    this.selectSectionForm.patchValue({
      selected_section: undefined
    });
    this.navigateUrl();
    let classes;
    if (this.all_classes) {
      classes = this.all_classes
        .filter(clas => clas.id === selected_class.value);
    }

    if (classes.length > 0) {
      this.all_sections = classes[0].sections;
    }

    if (this.all_sections && this.all_sections.length > 0) {
      this.selectSectionForm.get('selected_section').setValue(this.all_sections[0].id);
      this.section_id_emit.emit(this.all_sections[0].id);
    }

  }

  sectionChange(selected_section) {
    this.navigateUrl();
    this.section_id_emit.emit(selected_section.value);
  }

  navigateUrl() {
    let selected_course_id = this.selectSectionForm.get('selected_course').value;
    let selected_class_id = this.selectSectionForm.get('selected_class').value;
    let selected_section_id = this.selectSectionForm.get('selected_section').value;
    if (selected_section_id) {
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id + "/" + selected_class_id + "/" + selected_section_id);
    } else if (selected_class_id) {
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id + "/" + selected_class_id);
    } else if (selected_course_id) {
      this.location.replaceState(this.entity + "/" + this.entityMenuItem + "/" + selected_course_id);
    }
  }

}
