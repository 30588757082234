import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StudentFee } from 'src/app/_models/student_fee';
import { GlobalService } from 'src/app/_services/global.service';
import { FeeService } from 'src/app/_services/operator/fee.service';

@Component({
  selector: 'app-student-fee',
  templateUrl: './student-fee.component.html',
  styleUrls: ['./student-fee.component.scss']
})
export class StudentFeeComponent implements OnInit {

  @Input() student_id: number;
  @Output() result = new EventEmitter<string>();
  student_fees: StudentFee[];
  total_fee: number;
  updating_fee: number;
  isUpdating:boolean = false;
  modifiedFee: number;
  displayedColumns: string[] = ['fee_type', 'amount'];
  constructor(private feeService: FeeService,
              private globalService: GlobalService) { }

  ngOnInit() {
    this.getStudentFee();
  }

  getStudentFee() {    
    this.globalService.startLoader();
    this.feeService.getStudentFee(this.student_id).subscribe(
      response => {
        this.student_fees= response['student_fee'];
        this.total_fee = response['total_fee'];
        this.updating_fee = this.total_fee;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Get Student Fee');
      }
    )
  }

  modifyFee(current_fee:any, event: any) {
    this.isUpdating = true;
    this.updating_fee = this.total_fee-parseInt(current_fee)+parseInt(event.target.textContent)
  }

  updateFee(student_fee: StudentFee, event:any ){
    const data = {
      student_fee_id: student_fee.id,
      student_id: student_fee.student_id,
      amount: parseInt(event.target.textContent)
    }

    if(this.updating_fee !== this.total_fee){
      this.globalService.startLoader();
      this.feeService.updateStudentFee(data).subscribe(
        response => {
          this.student_fees= response['student_fee'];
          this.total_fee = response['total_fee'];
          this.updating_fee = this.total_fee;
          this.isUpdating = false;
          this.result.emit("success");
          this.globalService.stopLoader();
        },
        error => {
          this.result.emit(error['error']);
          this.globalService.showError(error['error'], 'Update Student Fee');
        }
      )
    }
    
  }

   
   getTotalAmount() {
    return this.student_fees.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }

}
