import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReportService 
{
  private reportsURL = environment.API_URL + '/reports';
  
  constructor(private httpClient: HttpClient) { }

  getSectionWisePayments(section_id: number) {
    return this.httpClient.get(this.reportsURL + '/payments/section/' + section_id);
  }

  getClassWisePayments(class_id: number) {
    return this.httpClient.get(this.reportsURL + '/payments/class/' + class_id);
  }

  getCourseWisePayments(course_id: number) {
    return this.httpClient.get(this.reportsURL + '/payments/course/' + course_id);
  }

}
