import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-student-menu',
  templateUrl: './student-menu.component.html',
  styleUrls: ['./student-menu.component.scss']
})
export class StudentMenuComponent implements OnInit {

  @Input() studentMenuItem: string;  
  
  addStudent: boolean = false;
  viewStudents: boolean = false;
  searchStudent: boolean = false;
  section_id;
  class_id;
  course_id;

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute
  ) {     
    
  }

  ngOnInit() {
    this.getActivatedRouteParams();
    this.globalService.page_title.next('Students');
    if(this.studentMenuItem == 'add-student') {
      this.addStudentClick();
    } else if(this.studentMenuItem == 'view-students') {
      this.viewStudentsClick();
    } else if(this.studentMenuItem == 'search-student') {
      this.searchStudentClick()
    }
  }

  ngOnChanges() {
    if(this.studentMenuItem == 'view-students') {       
    }
  }

  getActivatedRouteParams() {
    this.activatedRoute.params.subscribe(
      params => { 
        this.course_id = params.course_id;
        this.class_id = params.class_id;
        this.section_id = params.section_id;                
      }
    )
  }

  addStudentClick() {
    if(this.router.url.indexOf("create") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/students/create']);           
    } 
    this.viewNothing();    
    this.addStudent = true;    
  }

  viewStudentsClick() {    
    if(this.router.url.indexOf("view") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/students/view']);           
    }
    this.viewNothing();    
    this.viewStudents = true;      
  }

  searchStudentClick() {
    this.router.navigateByUrl('/students/search');
    this.viewNothing();
    this.searchStudent = true;  
  }

  viewNothing() {
    this.addStudent = false;
    this.viewStudents = false;
    this.searchStudent = false;
  }

}
