<div class="edit-student-fee-container">
    <table mat-table [dataSource]="class_fees">
        <!-- Item Column -->
        <ng-container matColumnDef="fee_type">
            <th mat-header-cell *matHeaderCellDef> Fee Type </th>
            <td mat-cell *matCellDef="let class_fee"> {{class_fee.fee_type}} </td>
            <td mat-footer-cell *matFooterCellDef> Total </td>
        </ng-container>

        <!-- Cost Column -->
        <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef> Amount </th>
            <td mat-cell *matCellDef="let class_fee"> 
                <span contenteditable="true">{{class_fee.amount}} </span>
            </td>
            <td mat-footer-cell *matFooterCellDef>{{getTotalAmount()}} 600</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
    </table>

</div>