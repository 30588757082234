import { Component, OnInit, Input, Inject, Output,EventEmitter, OnChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewStudentDialogComponent } from '../view-student-dialog/view-student-dialog.component';
import { StudentService } from 'src/app/_services/operator/student.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-students-card-view',
  templateUrl: './students-card-view.component.html',
  styleUrls: ['./students-card-view.component.scss']
})
export class StudentsCardViewComponent implements OnInit {

  @Input() students: any;
  @Output() refreshStudentsList = new EventEmitter<boolean>();
  flipped: string;
  @Inject(MAT_DIALOG_DATA) public dialog_data: ViewStudentDialogComponent;

  constructor(public viewStudentDialog: MatDialog,
              private studentService: StudentService,
              private snackBar: MatSnackBar,
              
              private globalService: GlobalService) { }

  ngOnInit() {
  }

  flipBack(student_id) {
    this.flipped = 'flipped-' + student_id;
  }

  flipFront(student_id) {
    this.flipped = '';
  }

  changeView(viewType) {
    this.studentService.studentsView.next(viewType);
  }

  viewStudentDetails(student_id:number, dialog_tab_index: number) {
    this.globalService.startLoader();
    const dialogRef = this.viewStudentDialog.open(ViewStudentDialogComponent, {
      data: {
        student_id: student_id,
        tab_index: dialog_tab_index
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshStudentsList.emit(true);
      console.log(`Dialog result: ${result}`);
    });
    
  }

  deleteStudent(student_id: number) {
    this.globalService.startLoader();
    this.studentService.deleteStudent(student_id).subscribe(
      response => {
        this.refreshStudentsList.emit(true);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Delete')
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showMessage(error['error'], 'Delete');
      }
    )
  }
}
