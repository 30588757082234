<mat-toolbar class="menu-horizontal">
    <div class="d-flex align-center" style="display: inline-block;">
        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': viewStudents}" (click)="viewStudentsClick()">            
            <span>VIEW STUDENTS</span><br />
        </div>

        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': addStudent}" (click)="addStudentClick()">           
            <span>ADD STUDENT</span><br />
        </div>

        <div class="horizontal-menu-item" [ngClass]="{'horizontal-menu-item-active': searchStudent}" (click)="searchStudentClick()">           
            <span>SEARCH STUDENT</span><br />
        </div>

    </div>

</mat-toolbar>