<div class="dialog-view-port">
    <form class="select-form"  [formGroup]="studentFilterForm">
        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Course</mat-label>
            <mat-select formControlName="selected_course" (selectionChange)="getClasses($event)">
                <mat-option *ngFor="let course of all_courses" [value]="course.id">{{course.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Class</mat-label>
            <mat-select formControlName="selected_class" (selectionChange)="getSections($event)">
                <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Sections</mat-label>
            <mat-select formControlName="selected_sections" multiple>
                <mat-option *ngFor="let section of all_sections" [value]="section.id">{{section.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Fields</mat-label>
            <mat-select formControlName="selected_fields" multiple>
                <mat-option *ngFor="let field of all_fields" [value]="field.id">{{field.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <button mat-button (click)="generate()" color="custom-color" class="mt-3">Generate</button>

    </form>
</div>