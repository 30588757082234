<mat-toolbar class="title-header text-color-light">
    <span>
        <img src="https://bsbugudiedusociety.com/school/images/logo.jpg"
            class="logo">
    </span>
    <span>BRILLIANT EDUCATIONAL SOCIETY</span>
</mat-toolbar>

<div class="login-container">        
    <mat-card>
        <mat-card-header class="login-card-header">
            <mat-card-title><strong><i>Drop your credentials below...</i></strong></mat-card-title>                
        </mat-card-header>
        
        <mat-card-content flex>
            <!-- Form -->
            <form class="login-form-container" [formGroup]="loginForm" (ngSubmit)="loginSubmit()">

                    <!-- Email -->
                    <mat-form-field appearance="fill">                                                    
                        <input matInput placeholder="Username" type="email" formControlName="username">                            
                        <mat-icon matPrefix>person</mat-icon>
                    </mat-form-field>
                    
                    <!-- Email -->
                    <mat-form-field appearance="fill">
                        <mat-icon matPrefix>vpn_key</mat-icon>
                        <input matInput placeholder="Password" type="password" formControlName="password">
                    </mat-form-field>                                      
                    <button mat-button color="custom-color">Login</button>
                </form>
                <!-- Form -->
        </mat-card-content>                        
    </mat-card>
</div>