import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { StudentService } from 'src/app/_services/operator/student.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ViewStudentDialogComponent } from '../view-student-dialog/view-student-dialog.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';
import { ActivatedRoute, Router } from '@angular/router';
export interface ViewStudentsDialogData {
  student_id: number;
  tab_index: number;
}

@Component({
  selector: 'app-view-students',
  templateUrl: './view-students.component.html',
  styleUrls: ['./view-students.component.scss']
})
export class ViewStudentsComponent implements OnInit, OnChanges {

  studentMenuItem: string;
  viewStudents: boolean = true;
  addStudent: boolean = false;
  searchStudent: boolean = false;
  students: any = [];
  section_id: number;
  class_id: number;
  course_id: number;
  viewType: string;

  constructor(private studentService: StudentService,
              
              private globalService: GlobalService,
              private activatedRoute: ActivatedRoute,
              private router: Router) { 
                this.activatedRoute.params.subscribe(
                  params => {                    
                    this.course_id = params.course_id;
                    this.class_id = params.class_id;
                    this.section_id = params.section_id;
                  }
                )
              }

  ngOnInit() {
    this.studentMenuItem = 'view-students';          
    this.studentService.studentsViewObservable.subscribe(
      data => {
        this.viewType = data;        
      }
    )
  }

  ngOnChanges() {
    this.studentService.studentsViewObservable.subscribe(
      data => {
        this.viewType = data;        
      }
    )
  }

  addStudentClick() {
    this.viewNothing();
    this.addStudent = true;
  }

  viewStudentsClick() {
    this.viewNothing();
    this.viewStudents = true;      
    if(this.section_id) {
      this.globalService.startLoader();
      this.studentService.getStudentsBySection(this.section_id).subscribe(
        response => {
          this.students = response;
          this.globalService.stopLoader();
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], "Students");
        }
      )
    }    
  }

  searchStudentClick() {
    this.section_id = undefined;
    this.viewNothing();    
    this.searchStudent = true;
  }
  

  selectedSection(section_id: number) {
    this.section_id = section_id;
    if (this.viewStudents) {
      this.viewStudentsClick();
    }
  }

  selectedClass(class_id: number) {
    this.class_id = class_id;    
  }

  selectedCourse(course_id: number) {    
    this.course_id = course_id;
  }

  viewNothing() {
    this.viewStudents = false;
    this.addStudent = false;
    this.searchStudent = false;
  }

  refreshStudentsList(refresh: boolean) {
    if(refresh) {
      this.viewStudentsClick();
    }
  }
  
}
