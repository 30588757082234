<mat-card class="transaction-form-card">
    <mat-card-header>
        <mat-card-title>Income / Expenditure</mat-card-title>
    </mat-card-header>
    <mat-divider></mat-divider>
    <mat-card-content>
        <form [formGroup]="transactionForm" class="transaction-form">
            <mat-form-field floatLabel="never">
                <input matInput formControlName="transaction_date" [matDatepicker]="picker" placeholder="Transaction date" required>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker touchUi #picker></mat-datepicker>
            </mat-form-field><br />
            <mat-form-field floatLabel="never">
                <input matInput formControlName="amount" placeholder="Amount" required>
                <mat-icon matSuffix>money</mat-icon>
            </mat-form-field><br />
            <mat-form-field floatLabel="never">
                <input matInput formControlName="purpose" placeholder="Purpose" required>
                <mat-icon matSuffix>description</mat-icon>
            </mat-form-field><br />
            <mat-form-field>
                <ngx-mat-file-input formControlName="receipt_file" (change)="onFileInput($event)">
                    <mat-icon ngxMatFileInputIcon>folder</mat-icon>
                </ngx-mat-file-input>
            </mat-form-field><br />
            <mat-form-field floatLabel="never">
                <mat-chip-list #chipList aria-label="Tag selection">
                    <mat-chip
                      *ngFor="let tag of tags"
                      [selectable]="selectable"
                      [removable]="removable"
                      (removed)="removeTag(tag)">
                      {{tag}}
                      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                    </mat-chip>
                    <input
                      placeholder="Tag"
                      #tagInput
                      formControlName="tagCtrl"
                      [matAutocomplete]="auto"
                      [matChipInputFor]="chipList"
                      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                      (matChipInputTokenEnd)="addTag($event)">
                  </mat-chip-list>
            </mat-form-field>

            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
                  {{tag}}
                </mat-option>
              </mat-autocomplete>
              <br />
            
        </form>
        <div>
            <button mat-button color="green-color" (click)="cashInClicked()">
                Cash In
                <mat-icon aria-hidden="false" aria-label="Cash In">arrow_downward</mat-icon>
            </button>
            <button mat-button color="red-color" (click)="cashOutClicked()">
                Cash Out
                <mat-icon aria-hidden="false" aria-label="Cash In">arrow_upward</mat-icon>
            </button>
        </div>
    </mat-card-content>
</mat-card>