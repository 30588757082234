<div class="pay-now-position-left" *ngIf="showPreviousPayments">
    <form [formGroup]="feeTypeSelectionForm">
        <mat-select formControlName="fee_type_id" placeholder="Select Fee Type"
                        (selectionChange)="getFeeTypeIdSelect($event)">
            <mat-option value="" selected>Total Fee</mat-option>
            <mat-option *ngFor="let fee_type of student_fee_types" [value]="fee_type.fee_type_id">
                {{fee_type.name}}</mat-option>
        </mat-select>
    </form>
</div>

<div class="pay-now pay-now-position-right" (click)="payNowClick()" *ngIf="showPreviousPayments">
    Pay now
</div>

<div class="pay-now pay-now-position-right" (click)="previousPaymentsClick()" *ngIf="showPaymentForm">
    Previous Payments
</div>


<div class="payment-history-custom-width" *ngIf="student_id && showPreviousPayments">    
    <app-previous-payments [student_id]="student_id" [fee_type_id]="fee_type_id"></app-previous-payments>    
</div>

<div class="payment-form mt-5" *ngIf="student_id && showPaymentForm">
    <app-payment-form [student_id]="student_id" (selected_fee_type)="selected_fee_type($event)"></app-payment-form>
</div>