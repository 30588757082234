import { Component, OnInit, Input, Inject, Output,EventEmitter, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewStudentDialogComponent } from '../view-student-dialog/view-student-dialog.component';
import { StudentService } from 'src/app/_services/operator/student.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';
import { PrintService } from 'src/app/_services/operator/print.service';

@Component({
  selector: 'app-students-list-view',
  templateUrl: './students-list-view.component.html',
  styleUrls: ['./students-list-view.component.scss']
})
export class StudentsListViewComponent implements OnInit {

  @Input() students: any;
  isAdmin: boolean;
  @Output() refreshStudentsList = new EventEmitter<boolean>();

  constructor(public viewStudentDialog: MatDialog,
              private studentService: StudentService,
              private globalService: GlobalService,
              public printService: PrintService) { }

  ngOnInit() {
    this.isAdmin = this.globalService.isUserAdmin();
  }

  viewStudentDetails(student_id:number, dialog_tab_index: number) {
    this.globalService.startLoader();
    const dialogRef = this.viewStudentDialog.open(ViewStudentDialogComponent, {
      data: {
        student_id: student_id,
        tab_index: dialog_tab_index
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.refreshStudentsList.emit(true);
      console.log(`Dialog result: ${result}`);
    });
    
  }

  deleteStudent(student_id: number) {
    this.globalService.startLoader();
    this.studentService.deleteStudent(student_id).subscribe(
      response => {
        this.refreshStudentsList.emit(true);        
        this.globalService.showMessage(response['message'], 'Delete');        
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.showError(error['error'], 'Delete');
        this.globalService.stopLoader();
      }
    )
  }

  changeView(viewType) {
    this.studentService.studentsView.next(viewType);
  }

  generateBonafide(student_id: number) {
    this.printService
        .getBonafide(student_id)
        .subscribe(
          response => {
            this.printService.open(response['url']);
          },
          error => {
            console.log(error);
          }
        )

  }
}
