import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AlumniService {

  private alumniURL = environment.API_URL + '/alumni';

  constructor(private httpClient: HttpClient) { }

  createAlumni(data) {
    return this.httpClient.post(this.alumniURL, data);
  }

  getBatches() {
    return this.httpClient.get(this.alumniURL + '/batches');
  }

  getCourses(batch: string) {
    return this.httpClient.get(this.alumniURL + '/courses/' + batch);
  }

  getClasses(batch: string, course_id: number) {
    return this.httpClient.get(this.alumniURL + '/classes/' + batch + '/' + course_id);
  }

  getAlumni(batch: string, class_id: number) {
    return this.httpClient.get(this.alumniURL + '/' + batch + '/' + class_id);
  }

  searchAlumni(student_name: string) {
    return this.httpClient.get(environment.API_URL + '/search/' + student_name);
  }
}
