<!--<div style="text-align: right;" *ngIf="students && students.length > 0">-->
<!--    <mat-select style="width:150px" #name placeholder="Change view" (selectionChange)="changeView($event.value)">-->
<!--        <mat-option value="listView">List View</mat-option>-->
<!--        <mat-option value="cardView">Cards View</mat-option>-->
<!--    </mat-select>-->
<!--</div>-->

<div *ngFor="let student of students" class='student-card-container mt-4'>
    <div layout="row" layout-wrap  class='flippable-card' [ngClass]="{'flipped':flipped=='flipped-'+student.id}"
        id="student-card-id-{{student.id}}">
        <!-- (click)='flipped = !flipped' -->
        <mat-card class="student-card-front">
            <mat-card-header>
                <div mat-card-avatar class="student-image" [ngStyle]="{'background-image': 'url(' + student.student_image + ')'}"></div>
                <mat-card-title>{{student.student_name}}</mat-card-title>
                <mat-card-subtitle>s/o {{student.father_name}}</mat-card-subtitle>
                <div class="student-action-menu">
                    <button mat-icon-button class="default-background" (click)="viewStudentDetails(student.id, 0)">
                        <mat-icon aria-label="edit Student">edit</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-divider style="background: black;"></mat-divider>
            <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg"
                alt="Photo of a Shiba Inu"> -->
            <mat-card-content class="text-center">
                <br />
                <mat-list role="studentInfoList" class="ml-5">
                    <mat-list-item role="studentInfoItem">
                        <span><strong>Mobile Number : </strong>{{student.mobile_number}}</span>
                    </mat-list-item>
                    <mat-list-item role="studentInfoItem">
                        <span><strong>Date of Birth : </strong></span>
                    </mat-list-item>
                    <mat-list-item role="studentInfoItem">
                        <span><strong>Address : </strong></span>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
            <mat-card-actions class="text-center">
                <button mat-button color="custom-color" (click)="flipBack(student.id)">VIEW DETAILS</button>
            </mat-card-actions>
        </mat-card>

        <mat-card class="student-card-back">
            <mat-card-header>
                <div mat-card-avatar class="student-image" [ngStyle]="{'background-image': 'url(' + student.student_image + ')'}"></div>
                <mat-card-title>{{student.student_name}}</mat-card-title>
                <mat-card-subtitle>s/o {{student.father_name}}</mat-card-subtitle>
                <div class="student-action-menu">
                    <button mat-icon-button class="default-background" (click)="viewStudentDetails(student.id, 0)">
                        <mat-icon aria-label="edit Student">edit</mat-icon>
                    </button>
                </div>
            </mat-card-header>
            <mat-divider style="background: black;"></mat-divider>
            <!-- <img mat-card-image src="https://material.angular.io/assets/img/examples/shiba2.jpg"
                        alt="Photo of a Shiba Inu"> -->
            <mat-card-content>
                <br />
                <mat-list role="studentParticularslList" class="student-card-back-list-items">
                    <mat-list-item role="studentParticularslListItem">
                        <button mat-button color="custom-color" (click)="viewStudentDetails(student.id, 1)">Student Fee</button>
                    </mat-list-item>
                    <mat-list-item role="studentParticularslListItem">
                        <button mat-button color="custom-color" (click)="viewStudentDetails(student.id, 2)">Payments</button>
                    </mat-list-item>
                    <mat-list-item role="studentParticularslListItem">
                        <button mat-button color="custom-color" (click)="viewStudentDetails(student.id, 3)">Attendance</button>
                    </mat-list-item>
                    <mat-list-item role="studentParticularslListItem">
                        <button mat-button color="custom-color" (click)="viewStudentDetails(student.id, 4)">Marks</button>
                    </mat-list-item>
                </mat-list>
            </mat-card-content>
            <mat-card-actions class="text-center">
                <button mat-button color="custom-color" (click)="flipFront(student.id)">VIEW STUDENT</button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
