import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-transaction-menu',
  templateUrl: './transaction-menu.component.html',
  styleUrls: ['./transaction-menu.component.scss']
})
export class TransactionMenuComponent implements OnInit {

  @Input() transactionMenuItem: string;
  viewTransactions: boolean = false;
  createTransaction: boolean = false;

  constructor(public globalService: GlobalService,
    private router: Router) { }

  ngOnInit() {
    this.globalService.page_title.next('Income & Expense');
    if(this.transactionMenuItem == 'view-transactions') {
      this.viewTransactionsClick();
    } else if(this.transactionMenuItem == 'create-transaction') {
      this.createTransactionClick();
    }
  }

  viewTransactionsClick() {
    if(this.router.url.indexOf("view") === -1) {
      this.router.navigate(['/transactions/view']);           
    } 
    this.viewNothing();    
    this.viewTransactions = true; 
  }

  createTransactionClick() {
    if(this.router.url.indexOf("create") === -1) {
      this.router.navigate(['/transactions/create']);           
    } 
    this.viewNothing();    
    this.createTransaction = true; 
  }

  viewNothing() {
    this.viewTransactions = false;
    this.createTransaction = false;
  }
}
