import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { class_fee } from 'src/app/_models/class_fee';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';
import { FeeService } from 'src/app/_services/operator/fee.service';


@Component({
  selector: 'app-edit-student-fee',
  templateUrl: './edit-student-fee.component.html',
  styleUrls: ['./edit-student-fee.component.scss']
})

export class EditStudentFeeComponent implements OnInit,OnChanges {

  @Input() student_id: number;
  @Input() section_id: number;
  class_fees: class_fee[];
  displayedColumns: string[] = ['fee_type', 'amount'];
  constructor(private feeService: FeeService,
              private globalService: GlobalService) { }

  ngOnInit() {
  }

  

  ngOnChanges(changes: SimpleChanges) {
    this.student_id = 7;    
    if(this.student_id!= undefined && this.section_id != undefined) {
      this.globalService.startLoader();
      this.feeService.getClassFee(this.section_id).subscribe(
        response => {          
          this.class_fees = response['class_fee'];
          this.globalService.stopLoader();
          this.globalService.showMessage("Student Fee updated successfully", "Student Fee");
        },
        error => {
          this.globalService.stopLoader();
          this.globalService.showError(error['error'], 'Student Fee');
        }
      )
    }
  }

  /** Gets the total cost of all transactions. */
  getTotalAmount() {
    // return this.class_fees.map(t => t.amount).reduce((acc, value) => acc + value, 0);
  }

}
