import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.scss']
})
export class AddPaymentComponent implements OnInit, OnChanges {

  @Input() students:any;
  
  student_fee_types: any;
  studentSelected: boolean = false;
  student_id: number;
  fee_type_id: number;
  pending_balance: number;
  payment_saved: boolean = false;
  viewStudents: boolean = true;
  viewPreviousPayments: boolean = false;
  viewPaymentForm: boolean = false;
  student_details: any;

  constructor(public globalService: GlobalService) { }

  ngOnInit() {
  }

  ngOnChanges() { 
    if (this.student_id != undefined) {
      this.viewStudents = true;
    }

    if (this.students.length == 0) {
      this.studentSelected = false;
      this.student_id = undefined;
    }
  }

  getStudent(student_id: number) {
    this.student_id = student_id;
    this.studentSelected = true;
    this.viewStudents = false;
    this.viewPreviousPayments = true;
    this.student_details = this.students.filter(student => student.id === this.student_id)[0];
  }
  
  selected_fee_type(fee_type_id: any) {
    this.fee_type_id = fee_type_id;
  }
  
  fee_type_pending_balance(pending_balance: number) {
    this.pending_balance = pending_balance;
  }

  viewStudentsClick()
  {
    this.viewNothing();
    this.viewStudents = true;
  }

  viewPreviousPaymentsClick()
  {
    this.student_details = this.students.filter(student => student.id === this.student_id)[0];
    this.viewNothing();
    this.viewPreviousPayments = true;
  }

  viewPaymentFormClick()
  {
    this.student_details = this.students.filter(student => student.id === this.student_id)[0];
    this.viewNothing();
    this.viewPaymentForm = true;
  }

  viewNothing()
  {
    this.viewStudents = false;
    this.viewPreviousPayments = false;
    this.viewPaymentForm = false;
  }

}
