export class StudentPersonalDetails {
    public student_id: number;
    public mother_name: string;
    public parent_occupation: string;
    public parent_annual_income: string;
    public student_email: string;
    public gender: string;
    public aadhaar_number: string;
    public caste: string;
    public mother_tongue: string;
    public religion: string;
    public nationality: string;
    public date_of_birth: string;
    public identification_marks: string;
    public address: string;
}