<app-attendance-menu [attendanceMenuItem]="attendanceMenuItem"></app-attendance-menu>

<app-select-section (section_id_emit)="selectedSection($event)"></app-select-section>

<div class="page-content-view-port mat-elevation-z8" *ngIf="section_id">
    <form [formGroup]="attendanceForm" class="text-center select-session-form" *ngIf="section_id">
        <mat-form-field style="margin-right:25px;" floatLabel="never">
            <mat-select formControlName="select_session" placeholder="Select Session" (selectionChange)="getSession($event)">
                <mat-option value="" selected>Select Session</mat-option>
                <mat-option *ngFor="let session of sessions" [value]="session.id">
                    {{session.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="date-field" floatLabel="never">
            <input matInput [matDatepicker]="doa_picker" formControlName="attendance_date" (dateChange)="onDateChange($event)"  placeholder="Date of Attendance" name="date_of_attendance" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="doa_picker"></mat-datepicker-toggle>
            <mat-datepicker touchUi #doa_picker></mat-datepicker>
        </mat-form-field>
    </form>

    <mat-table [dataSource]="students" *ngIf="attendanceForm.valid">
        <ng-container matColumnDef="s_no">
            <mat-header-cell *matHeaderCellDef> S.No </mat-header-cell>
            <mat-cell *matCellDef="let student; let i = index;"> {{i+1}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="student_name">
            <mat-header-cell *matHeaderCellDef> Student Name </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.student_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="father_name">
            <mat-header-cell *matHeaderCellDef> Father Name </mat-header-cell>
            <mat-cell *matCellDef="let student"> {{student.father_name}} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status_btn">
            <mat-header-cell *matHeaderCellDef class="text-center" style="justify-content: center;"> Status </mat-header-cell>
            <mat-cell *matCellDef="let student" style="justify-content: center;">
                <div class="attendance-buttons">
                    <button *ngIf="absent_ids.indexOf(student.id) > -1" mat-button class="present-color-fade" (click)="presentClick(student.id)">Present</button>
                    <button *ngIf="absent_ids.indexOf(student.id) === -1" mat-button class="present-color" (click)="presentClick(student.id)">Present</button> &nbsp;&nbsp;&nbsp;
                    <button *ngIf="absent_ids.indexOf(student.id) === -1" mat-button class="absent-color-fade" (click)="absentClick(student.id)">Absent</button>
                    <button *ngIf="absent_ids.indexOf(student.id) > -1" mat-button class="absent-color" (click)="absentClick(student.id)">Absent</button>&nbsp;&nbsp;&nbsp;
                </div>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>

    <div class="attendance-action-buttons" *ngIf="attendanceForm.valid">
        <button mat-button color="custom-color" (click)="markAttendanceClick()">Mark Attendance</button>
      <br/>
        <button mat-button color="custom-color" (click)="deleteAttendanceClick()" *ngIf="showDeleteAttendance">Delete Attendance</button>
    </div>
    <br/>

</div>
