import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Transaction } from 'src/app/_models/transaction';
import { GlobalService } from 'src/app/_services/global.service';
import { PrintService } from 'src/app/_services/operator/print.service';
import { TransactionService } from 'src/app/_services/transaction.service';
import { TransactionCardComponent } from '../transaction-card/transaction-card.component';
import { TransactionFormComponent } from '../transaction-form/transaction-form.component';

export interface TransactionFormDialogData {
  transaction_id: number;
}

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {

  transactionMenuItem: string;
  transactions: Transaction[];
  total_income: number = 0;
  total_expense: number = 0;
  transactionsFilterForm: FormGroup;
  isAdmin: boolean;
  from_date: string;
  to_date: string;
  income_type: string;
  tag: string;
  all_tags : string[];
  displayedColumns = [ 's_no', 'transaction_date', 'purpose', 'income', 'expense', 'proof', 'action' ];

  constructor(private fb: FormBuilder,
    private transactionService: TransactionService,
    public globalService: GlobalService,
    public transactionFormDialog: MatDialog,
    private location: Location,
    private printService: PrintService,
    private activatedRoute: ActivatedRoute) {
      this.activatedRoute.params.subscribe(
        params => {
          this.from_date = params.from_date,
          this.to_date = params.to_date,
          this.income_type = params.income_type,
          this.tag = params.tag
        }
      );
    }

  ngOnInit() {
    this.transactionMenuItem = 'view-transactions';
    this.transactionsFilterForm = this.fb.group({
      from_date: [],
      to_date: [ new Date() ],
      type: [ 'both' ],
      tag: ['all']
    });

    this.getAllTransactionTags();

    if (this.from_date && this.to_date && this.income_type) {
      this.transactionsFilterForm.patchValue({
        from_date: this.from_date,
        to_date: this.to_date,
        type: this.income_type,
        tag: this.tag
      });
      this.filterTransactions();
    }

    this.isAdmin = this.globalService.isUserAdmin();
    if (this.globalService.isUserIncharge()) {
      this.transactionsFilterForm.patchValue({
        from_date: new Date(),
        to_date: new Date(),
        type: this.income_type ?? 'both',
        tag: this.tag ?? 'all'
      });
      this.filterTransactions();
      this.transactionsFilterForm.get('from_date').disable();
      this.transactionsFilterForm.get('to_date').disable()
    }
  }

  getAllTransactionTags() {
    this.transactionService.getTags().subscribe(
      tags => {
        this.all_tags = tags; 
      },
      error => {
        this.globalService.showError(error.error, 'Tags');
      }
    )
  }

  filterTransactions() {

    const from = new Date(this.filterForm('from_date')).toISOString();
    const to = new Date(this.filterForm('to_date')).toISOString();
    const type = this.filterForm('type');
    const tag = this.filterForm('tag');

    this.location.replaceState("transactions/view/" + from + "/" + to + "/" + type + "/" + tag);

    const transaction_data =  {
      from_date :  from,
      to_date :  to,
      type: type,
      tag: tag
    }
    this.globalService.startLoader();
    this.transactionService
        .filterTransactions(transaction_data)
        .subscribe(
          response => {
            this.transactions = response['transactions'];
            this.total_income = response['total_income'];
            this.total_expense = response['total_expense'];
            this.globalService.stopLoader();
          },
          error => {
            this.globalService.stopLoader();
            this.globalService.showError(error['error'], 'Filter');
          }
        )

  }

  filterForm(form_field) {
    return this.transactionsFilterForm.get(form_field).value;
  }

  openTransactionFormDialog(transaction_id: number) {
    this.globalService.startLoader();
    const dialogRef = this.transactionFormDialog.open(TransactionCardComponent, {
      data: {
        transaction_id: transaction_id,
      }
    });
    this.globalService.stopLoader();
    dialogRef.afterClosed().subscribe(
      data => {
        this.getAllTransactionTags();
        this.filterTransactions();
      }
    );
  }

  print() {
    const transaction_data =  {
      from_date :  new Date(this.filterForm('from_date')).toISOString(),
      to_date :  new Date(this.filterForm('to_date')).toISOString(),
      type: this.filterForm('type'),
      tag: this.filterForm('tag')
    }
    this.printService
        .transactionsPrint(transaction_data)
        .subscribe(
          response => {
            this.printService.open(response['url']);
          },
          error => {
            this.globalService.showError(error['error'], 'Forbidden');
          }
        );
  }

  deleteTransaction(id: number) {
    this.globalService.startLoader();
    if(confirm("Are you sure to delete this transaction")) {
      this.transactionService
        .deleteTransaction(id)
        .subscribe(
          response => {
            this.globalService.stopLoader();
            this.globalService.showMessage(response['message'], 'Delete');
            this.filterTransactions();
          },
          error => {
            this.globalService.stopLoader();
            this.globalService.showError(error['error'], 'Delete');
          }
        );
    }
    
  }
}
