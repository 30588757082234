import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PaymentService } from 'src/app/_services/operator/payment.service';
import { StudentPayment } from 'src/app/_models/student_payment';
import { GlobalService } from 'src/app/_services/global.service';
import { FeeService } from 'src/app/_services/operator/fee.service';

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.scss']
})
export class PaymentFormComponent implements OnInit, OnChanges {

  @Input() student_id: number;
  @Output() selected_fee_type = new EventEmitter<string>();

  addPaymentForm: FormGroup;
  studentPayment: StudentPayment;
  student_fee_types: any;

  constructor(private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private feeService: FeeService,
    private globalService: GlobalService) {
    this.addPaymentForm = this.formBuilder.group({
      fee_type_id: [''],
      receipt_date: [new Date()],
      receipt_number: [],
      amount: [],
      paid_by: [],
      collected_by: []
    });
  }

  ngOnInit() {
    this.globalService.startLoader();
    this.feeService.getFeeTypes(this.student_id).subscribe(
      response => {
        this.student_fee_types = response;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Class Fee');
      }
    )
  }

  ngOnChanges() {
    if(this.studentFeePaymentForm('fee_type_id') === '') {
      this.addPaymentForm.disable();
      this.addPaymentForm.get('fee_type_id').enable();
    }  else {
      this.addPaymentForm.enable();
    }
  }

  savePayment() {

    const studentPayment = {
      student_id: this.student_id,
      fee_type_id: this.studentFeePaymentForm('fee_type_id'),
      receipt_date: this.studentFeePaymentForm('receipt_date'),
      receipt_number: this.studentFeePaymentForm('receipt_number'),
      amount: this.studentFeePaymentForm('amount'),
      paid_by: this.studentFeePaymentForm('paid_by'),
      collected_by: this.studentFeePaymentForm('collected_by'),
      payments: ''
    }

    this.globalService.startLoader();
    this.paymentService.savePayment(studentPayment).subscribe(
      response => {
        this.paymentService.payment_saved.next(true);
        this.globalService.stopLoader();
        this.globalService.showMessage(response['message'], 'Status');
        this.addPaymentForm.reset();
        this.addPaymentForm.get('fee_type_id').setValue(studentPayment.fee_type_id);
      },
      error => {
        this.paymentService.payment_saved.next(false);
        this.globalService.stopLoader();
        if(studentPayment.fee_type_id === '') {
          this.globalService.showError("Incorrect Fee Type", 'Status');
        } else {
          this.globalService.showError(error['error'], 'Status');
        }
      }
    );
  }

  studentFeePaymentForm(form_field) {
    return this.addPaymentForm.get(form_field).value;
  }

  getFeeTypeIdSelect(fee_type_id) {
    if(this.studentFeePaymentForm('fee_type_id') === '') {
      this.addPaymentForm.disable();
      this.addPaymentForm.get('fee_type_id').enable();
    }  else {
      this.addPaymentForm.enable();
    }

    this.paymentService.pending_balance.subscribe(
      pending_balance => {
        if(this.studentFeePaymentForm('fee_type_id') === '') {
          this.addPaymentForm.disable();
          this.addPaymentForm.get('fee_type_id').enable();
        }  else {
          this.addPaymentForm.enable();

        }
        if(pending_balance === 0) {
          this.addPaymentForm.disable();
          this.addPaymentForm.get('fee_type_id').enable();
        }
      },
      error => {
        console.log(error);
      }
    );

      this.selected_fee_type.emit(fee_type_id.value);
  }

}
