import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FeeService {

  private feeURL = environment.API_URL + '/fee';
  
  constructor(private httpClient: HttpClient) { }

  getClassFee(section_id: number) {
    return this.httpClient.get(this.feeURL + '/class/' + section_id);
  }

  getStudentFee(student_id: number) {
    return this.httpClient.get(this.feeURL + '/student/' + student_id);
  }

  updateStudentFee(data:any) {
    return this.httpClient.put(this.feeURL + '/student_fee/' + data.student_fee_id, data);
  }
  
  getFeeTypes(student_id: number) {
    return this.httpClient.get(environment.API_URL + '/fee_types/' + student_id);
  }

  getAllFeeTypes() {
    return this.httpClient.get(environment.API_URL + '/fee_types');
  }
}
