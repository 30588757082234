import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ReportService } from 'src/app/_services/common/report.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelectChange } from '@angular/material/select';
import { GlobalService } from 'src/app/_services/global.service';
import { PaymentsByCourse } from 'src/app/_models/payments_by_course';
import { FeeDetails } from 'src/app/_models/payments_by_class';
import { PrintService } from 'src/app/_services/operator/print.service';
import { FeeService } from 'src/app/_services/operator/fee.service';

@Component({
  selector: 'app-course-wise-fee-details',
  templateUrl: './course-wise-fee-details.component.html',
  styleUrls: ['./course-wise-fee-details.component.scss']
})
export class CourseWiseFeeDetailsComponent implements OnInit {

  selected_course: number;
  payments_by_course: PaymentsByCourse[];
  course_wise_payments_response: any;
  feeTypeSelectionForm: FormGroup;
  fee_types: any;
  result: boolean = false;
  displayedColumns = ['s_no', 'class_name', 'committed_fee', 'paid_fee', 'pending_fee'];

  constructor(
    private printService: PrintService,
    private reportsService: ReportService,
    private feeService: FeeService,
    private fb: FormBuilder,
    private globalService: GlobalService
  ) {
    this.feeTypeSelectionForm = this.fb.group({
      fee_type_id: ['total_fee']
    })
  }

  ngOnInit() {
    this.globalService.page_title.next("Course Wise Fee Particulars");
    this.feeService.getAllFeeTypes().subscribe(
      response => {
        this.fee_types = response;
      },
      error => {
        this.globalService.showError(error['error'], 'Get Fee types');
      }
    ); 
  }

  selectedCourse(selected_course) {
    this.selected_course = selected_course;
    this.feeTypeSelectionForm.controls.fee_type_id.setValue('total_fee');
    this.globalService.startLoader();  
    this.reportsService.getCourseWisePayments(selected_course).subscribe(
      response => {        
        this.result = true;
        this.payments_by_course = response['class_wise_details'];
        this.payments_by_course.forEach(payment => {          
          payment.all_total_committed_fee = payment.total_committed_fee;
          payment.all_total_paid_fee = payment.total_paid_fee;
          payment.all_total_pending_fee = payment.total_pending_fee;
        });
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], 'Report');
      }
    );
  }

  selectedFeeType(fee_type: MatSelectChange) {
    this.globalService.startLoader();
    let fee_details: FeeDetails;
    const selected_fee_type = {
      text: (fee_type.source.selected as MatOption).viewValue,
      value: fee_type.source.value
    };    
    const fee_type_id = selected_fee_type.value;
    this.payments_by_course.forEach(payment => {
     if(fee_type_id == 'total_fee') {
        payment.all_total_committed_fee = payment.total_committed_fee;
        payment.all_total_paid_fee = payment.total_paid_fee;
        payment.all_total_pending_fee = payment.total_pending_fee;
      } else {
        fee_details = payment.fee_details.filter(fd => fd.fee_type_id == fee_type_id)[0];
        if(fee_details) {
          payment.all_total_committed_fee = fee_details.total_committed_fee;
          payment.all_total_paid_fee = fee_details.total_paid_fee;
          payment.all_total_pending_fee = fee_details.total_pending_fee; 
        } else {
          payment.all_total_committed_fee = '0';
          payment.all_total_paid_fee = '0';
          payment.all_total_pending_fee = '0';
        }     
      }
    });

    this.globalService.stopLoader();
  }

  /** Gets the total student fee of all students. */
  getTotalCommittedFee() {
    if(this.result) {
      return this.payments_by_course.map(pbc => parseInt(pbc.all_total_committed_fee)).reduce((acc, value) => acc + value, 0);
    }
  }

  /** Gets the total paid fee of all students. */
  getTotalPaidFee() {
    if(this.result) {
      return this.payments_by_course.map(pbc => parseInt(pbc.all_total_paid_fee)).reduce((acc, value) => acc + value, 0);
    }
  }

  /** Gets the total pending fee of all students. */
  getTotalPendingFee() {
    if(this.result) {
      return this.payments_by_course.map(pbc => parseInt(pbc.all_total_pending_fee)).reduce((acc, value) => acc + value, 0);
    }    
  }

  print() {
    const fee_type_id = this.feeTypeSelectionForm.get('fee_type_id').value;
    this.printService
        .courseWiseFeePrint(this.selected_course, fee_type_id)
        .subscribe(
          response => {
            this.printService.open(response['url']);
          },
          error => console.log(error)
        );
  }
}
