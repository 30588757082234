import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchStudent } from 'src/app/_models/search_student';
import { StudentService } from 'src/app/_services/operator/student.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-search-student',
  templateUrl: './search-student.component.html',
  styleUrls: ['./search-student.component.scss']
})
export class SearchStudentComponent implements OnInit {

  @Input() section_id: Number;
  studentMenuItem: string;
  searchStudentForm: FormGroup;
  searchData: SearchStudent;
  students: any;
  viewType: string;

  constructor(private fb: FormBuilder,
              private studentService: StudentService,
              
              private globalService: GlobalService) { 
    this.searchStudentForm = this.fb.group({
      student_name : []
    });
  }

  ngOnInit() {
    this.studentMenuItem = 'search-student';
    this.studentService.studentsViewObservable.subscribe(
      data => {
        this.viewType = data;
      }
    )
  }

  searchStudent() {
    
    this.globalService.startLoader();
    this.studentService.searchStudent(this.searchStudentForm.get('student_name').value).subscribe(
      response => {
        this.students = response;
        this.globalService.stopLoader();
      },
      error => {
        this.globalService.stopLoader();
        this.globalService.showError(error['error'], "Search");        
      }      
    )    
  }

  refreshStudentsList(refresh: boolean) {
    if(refresh) {
      this.searchStudent()
    }
  }
}
