<app-student-menu [studentMenuItem]="studentMenuItem"></app-student-menu>

<app-select-section (section_id_emit)="selectedSection($event)"></app-select-section>

<div class="students-container page-content-view-port" *ngIf="section_id && viewStudents && viewType === 'listView'" >    
    <app-students-list-view [students]="students" (refreshStudentsList)="refreshStudentsList($event)"></app-students-list-view>
</div>

<div class="students-container page-content-view-port" *ngIf="section_id && viewStudents && viewType === 'cardView'">
    <app-students-card-view [students]="students" (refreshStudentsList)="refreshStudentsList($event)"></app-students-card-view>    
</div>