<mat-toolbar class="page-content-view-port">
    <!-- Form -->
    <form class="select-form" [formGroup]="selectSectionForm">

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Course</mat-label>
            <mat-select formControlName="selected_course" (selectionChange)="getClasses($event)">
                <mat-option *ngFor="let course of all_courses" [value]="course.id">{{course.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Class</mat-label>
            <mat-select formControlName="selected_class" (selectionChange)="getSections($event)">
                <mat-option *ngFor="let class of all_classes" [value]="class.id">{{class.name}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field floatLabel="never">
            <mat-label style="color:red">Select Section</mat-label>
            <mat-select formControlName="selected_section" (selectionChange)="sectionChange($event)">
                <mat-option *ngFor="let section of all_sections" [value]="section.id">{{section.name}}</mat-option>
            </mat-select>
        </mat-form-field>


    </form>
    <!-- Form -->
</mat-toolbar>