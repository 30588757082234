import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GlobalService } from 'src/app/_services/global.service';

@Component({
  selector: 'app-payment-menu',
  templateUrl: './payment-menu.component.html',
  styleUrls: ['./payment-menu.component.scss']
})
export class PaymentMenuComponent implements OnInit {
  
  @Input() paymentMenuItem: string; 
  trackPayments: boolean = false;
  updatePayment: boolean = false;
  searchPayment: boolean = false;
  course_id;
  class_id;
  section_id;

  constructor(
    private router: Router,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(
      params => { 
        this.course_id = params.course_id;
        this.class_id = params.class_id;
        this.section_id = params.section_id;                
      }
    )
    this.globalService.page_title.next('Payments');
    if(this.paymentMenuItem == 'track-payments') {
      this.trackPaymentsClick();
    } else if(this.paymentMenuItem == 'update-payment') {
      this.updatePaymentClick();
    } else if(this.paymentMenuItem == 'search-payment') {
      this.searchPaymentClick()
    }
  }

  trackPaymentsClick() {
    if(this.router.url.indexOf("track") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/payments/track']);           
    } 
    this.viewNothing();    
    this.trackPayments = true;    
  }

  updatePaymentClick() {    
    if(this.router.url.indexOf("update") === -1 || (!this.course_id && !this.class_id && !this.section_id)) {
      this.router.navigate(['/payments/update']);           
    } 
    this.viewNothing();    
    this.updatePayment = true;      
  }

  searchPaymentClick() {
    this.router.navigateByUrl('/payments/search');
    this.viewNothing();
    this.searchPayment = true;  
  }

  viewNothing() {
    this.trackPayments = false;
    this.updatePayment = false;
    this.searchPayment = false;
  }

}
