<div class="page-content-view-port" style="background: transparent;margin-top:30px;display:flex">

    <mat-list role="list">
        <mat-list-item role="listitem" [routerLink]="['/reports/section-wise-fee-details']">
            <button mat-button color="custom-color">Section Wise Pending Fee</button>
        </mat-list-item><br/>
        <mat-list-item role="listitem" [routerLink]="['/reports/class-wise-fee-details']">
            <button mat-button color="custom-color">Class Wise Pending Fee</button>
        </mat-list-item><br/>
        <mat-list-item role="listitem" [routerLink]="['/reports/course-wise-fee-details']">
            <button mat-button color="custom-color">Course Wise Pending Fee</button>
        </mat-list-item><br/>
        <mat-list-item role="listitem">
            <button mat-button color="custom-color" (click)="openSectionWiseStudentsListDialog()">Section Wise Students List</button>
        </mat-list-item><br/>
    </mat-list>
    
</div>